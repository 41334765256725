import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-make-us',
  templateUrl: './what-make-us.component.html',
  styleUrls: ['./what-make-us.component.css']
})
export class WhatMakeUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
