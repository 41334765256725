<div class="row mt-3">
    <div class="col-md-2 d-flex align-items-baseline">
        <ng-select 
            [items]="pages" 
            bindLabel="label" 
            [clearable]="false" 
            [searchable]="false"
            [(ngModel)]="paginations.per_page" 
            bindValue="value" 
            placeholder="Sorting"
            (change)="paginationSetup('page')">
        </ng-select>
        <span> &nbsp;&nbsp; Record Per Page</span>
      </div>
      <div class="col-md-10">
        <nav aria-label="Page">
          <ul class="pagination justify-content-end">
            <li class="page-item">
              <a class="page-link" href="javascript:void(0);" aria-label="Previous" (click)="paginationSetup(1)">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0);" (click)="paginationSetup('P')">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
              </a>
            </li>
            <li *ngFor="let p of paginations.totalPage;" class="page-item"
              [ngClass]="{'active': p+1 == paginations.current_page}"><a class="page-link" href="javascript:void(0);"
                (click)="paginationSetup(p+1)">{{p+1}}</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0);" (click)="paginationSetup('L')">
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="javascript:void(0);" aria-label="Next"
                (click)="paginationSetup(paginations.totalPage.length)">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
</div>
