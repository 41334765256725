<div class="sidebar-wrapp border-right pr-3 h-100">
    <div class="sidebar-block">
        <ul class="pt-4">
            <li [routerLink]="['/' + 'dashboard/home']" routerLinkActive="active"> <a><i class="fas fa-th mr-3" aria-hidden="true"></i> Dashboard </a></li>
            <li [routerLink]="['/' + 'dashboard/booking/booking-list']" routerLinkActive="active"> <a><i class="fas fa-bold mr-3" aria-hidden="true"></i> Bookings </a></li>

            <li [routerLink]="['/' + 'dashboard/property-list']" routerLinkActive="active" *ngIf="userCurrentlySelectedRole == 'host'"> <a><i class="fas fa-university mr-3" aria-hidden="true"></i> Property </a></li>
            <li [routerLink]="['/' + 'dashboard/featured-packages']" routerLinkActive="active" *ngIf="userCurrentlySelectedRole == 'host'"> <a><i class="fas fa-audio-description mr-3" aria-hidden="true"></i> Featured Packages </a></li>
            <li [routerLink]="['/' + 'dashboard/featured-property-list']" routerLinkActive="active" *ngIf="userCurrentlySelectedRole == 'host'"> <a><i class="fas fa-universal-access mr-3" aria-hidden="true"></i> Featured Property </a></li>
            
            <li [routerLink]="['/' + 'dashboard/wallet']" routerLinkActive="active"> <a><i class="fas fa-credit-card mr-3" aria-hidden="true"></i> Wallet </a></li>
            <li [routerLink]="['/' + 'dashboard/reward-points']" routerLinkActive="active"> <a><i class="fas fa-registered mr-3" aria-hidden="true"></i> Reward Points </a></li>
            <li [routerLink]="['/' + 'dashboard/transactions']" routerLinkActive="active"> <a><i class="fas fa-book mr-3" aria-hidden="true"></i> Transactions </a></li>
            <li [routerLink]="['/' + 'dashboard/invoices']" routerLinkActive="active"> <a><i class="fas fa-file mr-3" aria-hidden="true"></i> Invoices </a></li>
            <li [routerLink]="['/' + 'dashboard/notifications']" routerLinkActive="active"> <a><i class="fas fa-bell mr-3" aria-hidden="true"></i> Notifications </a></li>
            <li [routerLink]="['/' + 'dashboard/wishlist']" routerLinkActive="active"> <a><i class="fas fa-vr-cardboard mr-3" aria-hidden="true"></i> Wishlist </a></li>
            <li [routerLink]="['/' + 'dashboard/reviewlist']" routerLinkActive="active"> <a><i class="fas fa-star-half-alt mr-3" aria-hidden="true"></i> Review </a></li>
            <li [routerLink]="['/' + 'dashboard/profile']" routerLinkActive="active"> <a><i class="fas fa-user-alt mr-3" aria-hidden="true"></i> Profile </a></li>
        </ul>
    </div>
</div>