<section class="address-slide-view gap-50 position-relative">
    <img src="../assets/images/cleaning-banner.jpg" class="img-fluid"/>

    <div class="container  banner-title ">
     
      <div class="row">
        <div class="col-md-12">
  <h1 class="banner-over-text"></h1>
        </div>
       
        
      </div>
      </div>
        
  </section>
  <section>
      <div class="container">
<div class="row">
    <div class="col-md-12 text-center">
        <h2>Book an appointment for Plumber</h2>
    </div>
    </div>
    <div class="row m-0 mt-5 bg1 align-items-center">
        <div class="col-sm-6 p-0">
            <div class="center-div">
                <h2 class="mb-4">Plumber</h2>
                <ul>
                    <li>PodsLiving will help you in management of your Property.</li>
                </ul>
                <a href="#" class="btn blue-btn">BOOK AN APPOINTMENT</a>
            </div>
        </div>
        <div class="col-sm-6 p-0">
            <img src="../assets/images/plumber-l.jpg" alt="" class="img-fluid"/>
        </div>
    </div>
    
      </div>
      </section>
