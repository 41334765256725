<section class="polices-view mt-70">
    <div class="container p-0">
        <div class="row">
            <div class="col-md-12 mt-70">
                <div class="text-center 404">
                    <h1 style="    font-size: 12rem; color: #f60202;">404</h1>
                    <h3>The requested URL [URL] was not found on this server</h3>
                    <div class="text-center mt-4 promotion-button-group mt-2">
                        <a href="#" class="podsliving-button border-0">Go Back</a>
                        </div>
                </div>
            </div>
        </div>
    </div>
    
    
</section>