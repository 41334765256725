import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloginComponent } from './home/prelogin.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { NiceSelectModule } from 'ng-nice-select';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { DatePipe } from '@angular/common';
import { SearchByComponent } from './search-by/search-by.component';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import { PodsTableModule } from './pods-table/pods-table.module';
import { DataTablesModule } from 'angular-datatables';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CURRENCY_FORMAT } from './app.constants';
import { FacebookModule } from 'ngx-facebook';
import { NgHelmetModule } from 'ng-helmet';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { GuestHomeComponent } from './pages/guest-home/guest-home.component';
import { GuestStudentHomeComponent } from './pages/guest-student-home/guest-student-home.component';
import { ElectricianComponent } from './pages/service-pages/electrician/electrician.component';
import { FacilityManagementComponent } from './pages/service-pages/facility-management/facility-management.component';
import { PlumberComponent } from './pages/service-pages/plumber/plumber.component';
import { CleaningComponent } from './pages/service-pages/cleaning/cleaning.component';
import { SharedModule } from 'src/shared/shared.module';
import { SocialLoginModule } from 'angularx-social-login';

@NgModule({
  declarations: [
    AppComponent,
    PreloginComponent,
    ComingSoonComponent,
    NotFoundComponent,
    SearchByComponent,
    EmailVerificationComponent,
    GuestStudentHomeComponent,
    GuestHomeComponent,
    ElectricianComponent,
    FacilityManagementComponent,
    PlumberComponent,
    CleaningComponent
  ],
  imports: [
    CommonModule,     
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    SharedModule, 
    SocialLoginModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule,
    AppRoutingModule,
    NgxSpinnerModule,
    NiceSelectModule,
    GooglePlaceModule,
    PodsTableModule,
    FacebookModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgHelmetModule.forRoot({ baseTitle: '| Replay Value' }),
    ToastrModule.forRoot({
      timeOut: 3000,
    }), // ToastrModule added
  ],
  providers: [
    DatePipe,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: DEFAULT_CURRENCY_CODE, useValue: CURRENCY_FORMAT },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
