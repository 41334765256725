<!-- <div class="tab-pane  active" id="privacy-policy"> -->

<div class="mt-70">
    <article>


        <h2>Privacy policy</h2>
        <ol>
            <li><strong>OVERVIEW</strong>
                <p>The following Privacy Policy contains information about the way and extent to which personal data is
                    processed by PodsLiving INC. Personal data is information that can be directly or indirectly
                    attributed to or associated with you personally,
                    such as your name or your email address etc. The Company ensures to follow the various laws, rules
                    and regulations prevailing in various countries for the data protection and is compliant with the
                    same. The data collected and gathered
                    by the Company is in accordance with the various laws in force and that any user wishing to know
                    more about the data collection or having objection to send across the data to the third party can
                    contact the Company. Any user is authorized
                    to have copy of their data and information collected by the Website and can request for the same by
                    contacting the Company. The data protection norms and policy mentioned hereunder are in compliance
                    with the various laws of the land
                    in force prevailing in various countries. In case of any discrepancy/contradiction with the law, the
                    law of the land shall prevail and the company shall make utmost efforts to comply with the same.</p>
            </li>
            <li><strong>NAME AND CONTACT DETAILS OF THE CONTROLLER RESPONSIBLE FOR PROCESSING</strong>
                <p>This Privacy Policy applies to the data processing performed by PodsLiving Inc., operating under its
                    brand PodsLiving (the "controller", hereinafter "company"), to be contacted at
                    https://podsliving.ca/contactus. To get any information
                    related to the data protection, please read the privacy policy of the Company properly along with
                    other related norms. To know more or to address your concerns to the data controller <a
                        href="https://podsliving.ca/contactus">click here</a>.</p>

            </li>
            <li><strong>DATA ASSEMBLING THROUGH OTHER PLATFORMS:</strong>
                <ol>
                    <li><strong>LOGGING IN WITH GOOGLE:</strong>
                        <ol>
                            <li>When you log in with Google by selecting "G continue with Google", a direct connection
                                is established with the servers of Google ("Google") (put the details of Google). Google
                                detects that you have used your login information
                                from the website as part of this process. We do not obtain your Google Account
                                information. Google then informs you that data from your Google Account will be made
                                available to us and indicates the specific data in question.
                                Registration with and the use of Google are governed by Google's privacy policy and
                                terms of service.</li>
                            <li>If you have expressly given your consent to Google pursuant to Article 6 (1) (a) of the
                                GDPR, your personal data will be transmitted to us as part of the registration process
                                via Google. We use the following information from
                                the transmitted data, which is stored by us until it is automatically deleted:
                                <ul class="listStyle">
                                    <li>your email address</li>
                                    <li>your name on your Google account (first and last name)</li>
                                    <li>the profile image (or the avatar) used on Google</li>
                                    <li>a link to your Google account</li>
                                    <li>your gender</li>
                                    <li>the top-level domain of your logged-in Google account</li>
                                    <li>the user domain you manage on Google (hosted domain, HD)</li>
                                </ul>
                            </li>
                            <li>This data is used to
                                <ul class="listStyle">
                                    <li>enable us to identify you as our contractual partner</li>
                                    <li>set up your user account</li>
                                    <li>check the entered data for plausibility</li>
                                </ul>
                            </li>
                            <li>The legal basis for the use of this data is Article 6 (1) (b) GDPR. Using this data
                                enables us to fulfill our contractual obligations which arise from our Terms of Service
                                (Article 6 (1) (b) GDPR). We will delete the collected
                                data no later than your termination of our platform’s usage contract.</li>
                            <li>You can block the connection within your Google account</li>
                        </ol>
                    </li>
                    <li><strong>FACEBOOK CONNECT</strong>
                        <ol>
                            <li>When you log in through Facebook Connect, a direct connection is created to the servers
                                at Facebook, 1601 South California Avenue, Palo Alto, CA 94304, USA ("Facebook").
                                Facebook detects that you have used your login information
                                from Company as part of this process.
                            </li>
                            <li>If you have expressly given your consent to Facebook pursuant to Article 6 (1) (a) of
                                the GDPR, your personal data will be transmitted to us as part of the registration
                                process via the social login. We use the following information
                                from the transmitted data, which is stored by us until it is automatically deleted:
                                <ul class="listStyle">
                                    <li>your email address</li>
                                    <li>your Facebook profile name (first and last name)</li>
                                    <li>the profile and wallpaper image you use on Facebook</li>
                                    <li>your age group (over 18, over 21 years old)</li>
                                    <li>a link to your Facebook account</li>
                                    <li>your gender</li>
                                    <li>the top-level domain of your logged-in Facebook account</li>
                                    <li>the time zone in which you are on Facebook</li>
                                </ul>
                            </li>
                            <li>This data is used to
                                <ul class="listStyle">
                                    <li>identify you as our contractual partner</li>
                                    <li>set up your user account</li>
                                    <li>check the entered data for plausibility</li>
                                </ul>
                            </li>
                            <li>The legal basis for the use of this data is Article 6 (1) (b) GDPR. Using this data
                                enables us to fulfill our contractual obligations which arise from our Terms of Service
                                (Article 6 (1) (b) GDPR). We will delete the collected
                                data no later than your termination of our platform’s usage contract.></li>
                            <li>You can block the connection within your Facebook account.</li>
                            <li>Please refer to Facebook’s privacy policy for details regarding the purpose and scope of
                                the data collection and further processing and use of the data by your service provider,
                                as well as regarding your associated rights and
                                the settings options you can use to protect your privacy.</li>
                        </ol>
                    </li>
                    <li><strong>Loggin Apple ID</strong>
                        <ol>
                            <li>When you log-in through Apple ID, it allows the user to sign in/log in without providing
                                additional information to the website/app. Apple shall provide the Company with unique
                                identifier, allowing the user to keep the information
                                private. All the information received by logging in through Apple ID shall be subject to
                                the privacy policy of Apple. </li>
                            <li>However, for the purpose of providing the users with the better experience, the
                                website/app may ask for additional information to the user that shall have to be
                                provided additionally by the user.</li>
                            <li>The legal basis for the use of this data is Article 6 (1) (b) GDPR. Using this data
                                enables us to fulfill our contractual obligations which arise from our Terms of Service
                                (Article 6 (1) (b) GDPR). We will delete the collected
                                data no later than your termination of our platform’s usage contract.</li>
                        </ol>
                    </li>
                    <li><strong>Background Information:</strong> For Members in the Canada, to the extent permitted by
                        applicable laws, we may obtain reports from public records of criminal convictions or sex
                        offender registrations. For Members outside Canada,
                        to the extent permitted by applicable laws and with your consent where required, we may obtain
                        the local version of police, background or registered sex offender checks. We may use your
                        information, including your full name and
                        date of birth, to obtain such reports.</li>
                    <li><strong>Enterprise Product Invitations and Account Management:</strong> Organizations that use
                        our Enterprise products may submit personal information to facilitate account management and
                        invitations to use enterprise products.</li>
                    <li><strong>Referrals:</strong> If you are invited to the website Platform, the person who invited
                        you can submit personal information about you such as your email address or other contact
                        information.</li>
                    <li><strong>Other Sources:</strong> To the extent permitted by applicable law, we may receive
                        additional information about you, such as references, demographic data or information to help
                        detect fraud and safety issues from third party
                        service providers and/or partners, and combine it with information we have about you. For
                        example, we may receive background check results or fraud warnings from identity verification
                        service providers for use in our fraud prevention
                        and risk assessment efforts. We may receive information about you and your activities on and off
                        the website Platform, or about your experiences and interactions from our partners. We may
                        receive health information, including but
                        not limited to health information related to contagious diseases.</li>
                </ol>
            </li>
            <li><strong>INFORMATION REQUIRED TO USE THE WEBSITE PLATFORM</strong>
                <p>We collect personal information about you when you use the website Platform. Without it, we may not
                    be able to provide you with all services requested. This information includes:</p>
                <ol>
                    <li><strong>Contact Information, Account, Profile Information:</strong> Such as your first name,
                        last name, phone number, postal address, email address, date of birth, and profile photo, some
                        of which will depend on the features you use.
                    </li>
                    <li>b)Each time you log in, technical information is stored about your device and your browser, as
                        well as information about your searches. This helps us to improve your overall user experience
                        on the website, as well as the overall services.
                    </li>
                    <li><strong>Payment Transaction Information:</strong> Such as payment instrument used, date and
                        time, payment amount, payment instrument expiration date and billing postcode, PayPal email
                        address, IBAN information, your address and other
                        related transaction details.
                    </li>
                    <li><strong>Identity Verification and Payment Information:</strong> Such as images of your
                        government issued ID (as permitted by applicable laws), your ID number or other verification
                        information, bank account or payment account information.</li>
                    <li>The legal basis for all of the above is Article 6 (1) (b) and (f) of the GDPR. You provide us
                        with data based on the contractual relationship between you and us. Our legitimation is also
                        derived from the protection of your identity
                        and the prevention of fraudulent activity.</li>
                </ol>
            </li>
            <li><strong>INFORMATION YOU CHOOSE TO GIVE US</strong>
                <p>You can choose to provide us with additional personal information. This information may include:</p>
                <ol>
                    <li><strong>Additional Profile Information:</strong> Such as gender, preferred language(s), city,
                        and personal description. Some of this information as indicated in your account settings is part
                        of your public profile page and will be
                        publicly visible;</li>
                    <li><strong>Address Book Contact Information:</strong> Address book contacts you import or enter
                        manually.</li>
                    <li><strong>Other Information:</strong> Such as when you fill in a form, add information to your
                        account, respond to surveys, post to community forums, participate in promotions, communicate
                        with our customer care team and other Members,
                        or share your experience with us. This may include health information if you choose to share it
                        with us.</li>
                </ol>
            </li>
            <li><strong>DATA RECEIVED THROUGH OTHER MEANS OF ACCESSING OUR WEBSITE/APPLICATION</strong>
                <p>When you access our website/application, the browser used on your device automatically sends
                    information to the server of our website/application and temporarily stores it in what is known as a
                    log file. We have no control over this. The
                    following information will also be collected without any action on your part and be stored until it
                    is automatically deleted:</p>
                <ol>
                    <li>IP address of the requesting internet-enabled device</li>
                    <li>date and time of access</li>
                    <li>name and URL of the retrieved file</li>
                    <li>website/application from which access took place (the Referrer URL)</li>
                    <li>browser you are using, and potentially the operating system of your internet-enabled computer,
                        as well as the name of your access provider</li>
                    <li>device used (e.g., a desktop computer or a smartphone)</li>
                    <li>language of the browser you are using</li>
                </ol>
            </li>
            <li><strong>INFORMATION AUTOMATICALLY COLLECTED BY USING THE WEBSITE PLATFORM AND OUR PAYMENT
                    SERVICES</strong>
                <p>When you use the website Platform and Payment Services, we automatically collect personal
                    information. This information may include:</p>
                <ol>
                    <li><strong>Geo-location Information:</strong> Such as precise or approximate location determined
                        from your IP address or mobile device’s GPS depending on your device settings. We may also
                        collect this information when you’re not using
                        the app if you enable this through your settings or device permissions.</li>
                    <li><strong>Usage Information:</strong> Such as the pages or content you view, searches for
                        Listings, booking you have made, and other actions on the website Platform.</li>
                    <li><strong>Log Data and Device Information:</strong> Such as details about how you've used the
                        website Platform (including if you clicked on links to third party applications), IP address,
                        access dates and times, hardware and software
                        information, device information, device event information, unique identifiers, crash data,
                        cookie data, and the pages you’ve viewed or engaged with before or after using the website
                        Platform. We may collect this information even
                        if you haven't created an account or logged in.</li>
                </ol>
            </li>
            <li><strong>LEGAL BASIS AND LEGITIMATE PURPOSE</strong>
                <ol>
                    <li>The legal basis for processing your IP address is Article 6 (1) (f) of the General Data
                        Processing Regulation (GDPR). Our legitimate interest is based on the purposes of data
                        collection listed below. We would like to point out that
                        we are unable to draw any direct conclusions regarding your identity from the data that is
                        collected, and that we refrain from doing so. We use the IP address of your device and the other
                        data listed above for the following purposes:
                        <ol>
                            <li>Ensuring that a trouble-free connection is established></li>
                            <li>Ensuring the comfortable use of our website/application</li>
                            <li>the evaluation of system security and stability</li>
                        </ol>
                    </li>
                    <li>The data is stored for a period of up to 7 days and is then deleted automatically. </li>
                    <li>We will delete the collected data no later than your termination of our platform’s usage
                        contract.</li>
                </ol>
            </li>
            <li><strong>COOKIES - GENERAL INFORMATION:</strong>
                <ol>
                    <li>We use cookies on our website on the basis of Article 6 (1) (f) GDPR. Our interest in optimizing
                        our website is deemed to be legitimate within the meaning of the aforementioned provision. To
                        know more about cookies, please click here.
                    </li>
                </ol>
            </li>
            <li><strong>INTEREST-BASED ADVERTISING</strong>

                <p>If you are not the user but the visitor of the website, you are advised to read the privacy policy
                    and data related norms carefully.</p>
                <p>The website wishes to serve its users with the best of its services. For the purpose of the same, the
                    Company provides the users with the interest based advertising. To know more,</p>
            </li>
            <li><strong>ANALYTICAL TOOLS:</strong>
                <ol>
                    <li>In order to customize and continuously optimize our websites in line with users’ needs, we
                        deploy tools on the basis of Article 6 (1) (f) GDPR which permit us to analyze the use of our
                        website.
                        
                        <ol>
                            <li><strong>GOOGLE ANALYTICS:</strong>
                                <ol>
                                    <li>We use Google Analytics, a web analytics service provided by Google. In doing
                                        so, pseudonymized usage profiles are created and cookies are used. The
                                        information generated by the cookie about your use of this website,
                                        e.g.
                                        <ul>
                                            <li>- browser type/version</li>
                                            <li>- operating system used</li>
                                            <li>- Referrer URL (the previously visited page)</li>
                                            <li>- hostname of the accessing computer (IP address)</li>
                                            <li>- time of server request</li>
                                        </ul>
                                        <p>is transmitted to a Google server in the US and stored there. Google complies
                                            with the Privacy Policy of the US Privacy Shield and is registered with the
                                            U.S. Department of Commerce's US Privacy Shield Program.
                                            Google is processing on behalf of us.</p>
                                    </li>
                                    <li>The information is used to evaluate the use of the website, to compile reports
                                        on the activity on the website, and to provide other services related to the use
                                        of the website and internet usage for the purposes of market
                                        research and to customize the design of these websites in line with the needs of
                                        users. This information may also be transferred to third parties if required by
                                        law or if third parties are contracted to process
                                        this data. Under no circumstances will your IP address be combined with any
                                        other data from Google. The IP addresses are anonymized, which means that it is
                                        not possible to identify specific individuals (“IP masking”).</li>
                                    <li>You can prevent the installation of cookies by setting your browser software
                                        accordingly. However, disabling cookies completely may mean that you will not be
                                        able to use all the features of our website. You may also
                                        prevent the collection of data generated by the cookie and related to your use
                                        of the website (including your IP address) and the processing of this data by
                                        Google by downloading and installing this browser
                                        add-on https://tools.google.com/dlpage/gaoptout?hl=en.
                                        As an alternative to the browser add-on, especially for browsers on mobile
                                        devices, you can prevent the collection by Google Analytics by clicking on
                                        this link. An opt-out cookie will be set which prevents the future
                                        collection of your data when you visit this website. The opt-out cookie is only
                                        valid in this browser and only for our website and is stored on your device. If
                                        you delete the cookies in this browser, you must set
                                        the opt-out cookie again. For more information regarding data privacy related to
                                        Google Analytics, visit the Google Analytics website:
                                         https://support.google.com/analytics/answer/6004245?hl=en
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li><strong>Option to object/opt-out:</strong>
                        <p>You can prevent the targeting technologies as described by activating the appropriate cookie
                            setting in your browse. In addition, you have the option of deactivating preference-based
                            advertising with the help of the preference
                            manager available <a href="/policies/intellectual-property-rights">here</a>.</p>
                    </li>
                    <li><strong>Third party services and plugins:</strong>
                        <ol>
                            <li><strong>GOOGLE MAPS</strong>
                                <ol>
                                    <li>We use Google Maps API for displaying interactive maps directly on our website
                                        and to enable the simplified use of map functionality. Your IP address is
                                        transmitted and stored on one of Google’s servers in the US for
                                        the usage of Google Maps functionality. Legal basis is Article 6 (1) (f) GDPR. 
                                    </li>
                                    <li>Please see the Terms of Service for Google Maps here. (Create a hyperlink). You
                                        can find further information on data privacy in Google’s Privacy Policy <a
                                            href="https://policies.google.com/privacy?hl=en" target="_blank">here</a>.</li>
                                </ol>
                            </li>
                            <li><strong>GOOGLE FONTS:</strong>
                                <ol>
                                    <li>On our website we use fonts (“Google Fonts”) provided by Google. For this, your
                                        browser loads the required font into your browser cache when our website is
                                        opened. This is necessary so that the browser can display a
                                        visually improved version of our texts. If your browser does not support this
                                        feature, a default font will be used by your computer for display. The
                                        integration of these fonts is completed by a server call, usually
                                        a Google server in the USA. This will communicate to the server that you have
                                        visited this website.</li>
                                    <li>When the page is accessed, cookies are not sent by users to the Google Fonts
                                        API. Data transferred in connection with the pageview is sent to
                                        resource-specific domains such as fonts.googleapis.com or fonts.gstatic.com.
                                        You will not be associated with any information collected or used in connection
                                        with the parallel use of authenticated Google services, such as Gmail. You can
                                        set your browser so that the fonts from Google servers
                                        are not loaded.</li>
                                    <li>Further information can be found in Google's privacy policy</li>
                                </ol>
                            </li>
                        </ol>
                        <p><strong>Disclaimer:</strong> For the purpose of providing the users with the best experience
                            and quality services, the Company uses various other Google services such as Google search
                            console, Google Ads etc. The website may use
                            various Google services and other services in future for which the user is advised to check
                            with the privacy policy of Google. Click here for Google privacy policy.</p>
                    </li>
                </ol>
            </li>
            <li><strong>HOW WE USE INFORMATION WE COLLECT</strong>
                <ol>
                    <li>Provide, Improve, and Develop the website Platform.</li>
                    <li>We use personal information to:
                        <ol>
                            <li>enable you to access the website Platform and make and receive payments,</li>
                            <li>enable you to communicate with other Members,</li>
                            <li>perform analytics, debug and conduct research,</li>
                            <li>provide customer service,</li>
                            <li>send you messages, updates, security alerts, and account notifications></li>
                        </ol>
                    </li>
                    <li>We may process this information:
                        <ol>
                            <li>to facilitate your referral invitations,</li>
                            <li>to share your details and facilitate trip planning, </li>
                            <li>for fraud detection and prevention, and </li>
                            <li>to facilitate your requests or for any other purpose you authorize, personalize and
                                customize your experience based on your interactions with the website Platform, your
                                search and booking history, your profile information and
                                preferences, and other content you submit, and</li>
                            <li>enable your use of our enterprise products. </li>
                        </ol>
                    </li>
                    <li>Create and Maintain a Trusted and Safer Environment. </li>
                    <li>We use personal information to:
                        <ol>
                            <li>detect and prevent fraud, spam, abuse, security and safety incidents, and other</li>
                            <li>harmful activity,</li>
                            <li>study and combat discrimination consistent with our Nondiscrimination Policy,</li>
                            <li>conduct security investigations and risk assessments,</li>
                            <li>verify or authenticate information provided by you,</li>
                            <li>conduct checks against databases and other information sources, including</li>
                            <li>background or police checks,</li>
                            <li>comply with our legal obligations, protect the health and well-being of our Guests,</li>
                            <li>Hosts, Hosts’ employees and members of the public,</li>
                            <li>resolve disputes with our Members,</li>
                            <li>enforce our agreements with third parties,</li>
                            <li>comply with law, respond to legal requests, prevent harm and protect our rights enforce
                                our Terms and other policies (e.g., Nondiscrimination Policy), and in connection with
                                the activities above, we may conduct profiling based
                                on your interactions with the website Platform, your profile information and other
                                content you submit to website, and information obtained from third parties. </li>
                        </ol>
                    </li>
                    <li>In limited cases, automated processes could restrict or suspend access to the website Platform
                        if such processes detect activity that we think poses a safety or other risk to website, our
                        community, or third parties. If you wish to
                        challenge the decisioning based on the automated process, please contact us via the Contact
                        Information section below.</li>
                    <li>Provide, Personalize, Measure, and Improve our Advertising and Marketing. </li>
                    <li>We may use personal information to:
                        <ol>
                            <li>send you promotional messages, marketing, advertising, and other information</li>
                            <li>based on your preferences and social media advertising through social media platforms,
                            </li>
                            <li>personalize, measure, and improve our advertising,</li>
                            <li>administer referral programs, rewards, surveys, sweepstakes, contests, or other</li>
                            <li>promotional activities or events sponsored or managed by company or its third- party
                                partners,</li>
                            <li>analyze characteristics and preferences to send you promotional messages, marketing,
                                advertising and other information that we think might be of interest to you, and invite
                                you to events and relevant opportunities.</li>
                        </ol>
                    </li>
                    <li>Provide Payment services. Personal information is used to enable or authorize third parties to
                        use Payment Services:
                        <ol>
                            <li>Detect and prevent money laundering, fraud, abuse, security incidents.</li>
                            <li>Conduct security investigations and risk assessments.</li>
                            <li>Comply with legal obligations (such as anti-money laundering regulations).</li>
                            <li>Enforce the Payment Terms and other payment policies.</li>
                            <li>With your consent, send you promotional messages, marketing, advertising, and</li>
                            <li>other information that may be of interest to you based on your preferences.</li>
                            <li>Provide and improve the Payment Services.</li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li><strong>DISCLOSURE OF INFORMATION TO THIRD PARTY:</strong>
                <p>When we join with other companies to offer or provide products, services, competitions or promotions,
                    PodsLiving INC and the other company may use your information to identify future promotional
                    opportunities and to fulfill promotional
                    or contractual obligations.</p>
                <p>PodsLiving INC fully complies with the Unsolicited Electronic Messages Act 2007. We will not share,
                    sell, rent, swap or authorize any third party to use your email address without your permission. If
                    you feel you have received an email
                    from us in error, please contact us at https://podsliving.ca/contactus </p>
            </li>
            <li><strong>YOUR RIGHTS</strong>
                <p>In addition to the right to revoke the consent you have granted to us, you are entitled to the
                    following further rights if the relevant legal requirements apply:</p>
                <ol>
                    <li>Right of access to information about your personal data stored with us pursuant to Article 15
                        GDPR and such other law of the land from where the user belongs.</li>
                    <li>Right of rectification of inaccurate personal data and right to have incomplete personal data
                        completed pursuant to Article 16 GDPR.</li>
                    <li>Right to erasure of your personal data stored by us pursuant to Article 17 GDPR</li>
                    <li>Right to restriction of processing of your data pursuant to Article 18 GDPR.</li>
                    <li>Right to data portability pursuant to Article 20 GDPR</li>
                </ol>
            </li>
            <li><strong>RIGHT TO OBJECT:</strong>
                <ol>
                    <li>Under the conditions of Article 21 (1) GDPR, data processing can be objected to on grounds
                        relating to the particular situation of the data subject.</li>
                    <li>The above general right of objection applies to all processing purposes described in this
                        Privacy Policy which are based on Article 6 (1) (f) GDPR. Unlike the special right of objection
                        to data processing for advertising purposes,
                        under the GDPR we are only obliged to implement such a general objection if you give us reasons
                        of overriding importance for this (for example, possible danger to life or health). In addition,
                        you may also contact the supervisory
                        authority responsible for the Company.</li>
                    <li>If you exercise your access right in a verified way, we will disclose the following information
                        in a portable and (if technically feasible) readily usable form to you: categories of personal
                        information collected, categories of sources
                        for that information, business or commercial purpose for the collection, categories of third
                        parties with whom we share the information, and the specific pieces of personal information we
                        collected on you.</li>
                    <li>We do not sell personal information and will not sell it without providing a right to opt out.
                    </li>
                    <li>Please note that we use third-party cookies for advertising purposes and may share personal
                        information with third parties for business purposes as further described above in this Privacy
                        Policy.</li>
                    <li>We will not discriminate against you for exercising the rights. In particular, we will not, deny
                        you goods or services, charge you different prices for goods or services, whether through
                        denying benefits or imposing penalties, provide
                        you with a different level or quality of goods or services, and/or threaten you with any of the
                        above.</li>
                </ol>
            </li>

        </ol>
        <p><strong>Disclaimer:</strong> This Privacy Policy shall be adapted from time to time to the actual
            circumstances and the legal situation. Please check the Privacy Policy before using our platform to keep up
            to date with any changes or updates.</p>
    </article>
</div>
<!-- </div> -->