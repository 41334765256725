<section class="address-slide-view gap-50 position-relative">
    <img src="../assets/images/info-banner.png" class="img-fluid" />

    <div class="container  banner-title ">

        <div class="row">
            <div class="col-md-12">
                <h1 class="banner-over-text">Information Centre</h1>
            </div>


        </div>
    </div>

</section>
<section>
    <div class="container">
        <div class="row">

            <div class="col-md-3 leftSidebar">
                <div class="theiaStickySidebar">
                    <ul class="nav nav-tabs dis-list">
                        <li class="heading-list">Canada Provincial Regulations</li>
                        <ul class="nav nav-tabs dis-list scoll-ul">
                            <li><a data-toggle="tab" href="#Alberta" class="active">Alberta</a></li>
                            <li><a data-toggle="tab" href="#british-columbia">British Columbia</a></li>
                            <li><a data-toggle="tab" href="#Manitoba">Manitoba</a></li>
                            <li><a data-toggle="tab" href="#new-brunswick">New Brunswick</a></li>
                            <li><a data-toggle="tab" href="#labrador">Newfoundland and Labrador</a></li>
                            <li><a data-toggle="tab" href="#nova-scotia">Nova Scotia</a></li>
                            <li><a data-toggle="tab" href="#ontario">Ontario</a></li>
                            <li><a data-toggle="tab" href="#island">Prince Edward Island (PEI)</a></li>
                            <li><a data-toggle="tab" href="#quebec">Quebec</a></li>
                            <li><a data-toggle="tab" href="#saskatchewan">Saskatchewan</a></li>
                        </ul>


                        <li class="heading-list">Canada Territorial Regulations</li>
                        <li><a data-toggle="tab" href="#territories">Northwest Territories</a></li>
                        <li><a data-toggle="tab" href="#nunavut">Nunavut</a></li>
                        <li><a data-toggle="tab" href="#yukon">Yukon</a></li>


                    </ul>

                    <!-- <ul class="nav nav-tabs dis-list">
            <li class="heading-list">Canada Territorial Regulations</li>
            <li><a data-toggle="tab" href="#Territories">Northwest Territories</a></li>
            <li><a data-toggle="tab" href="#Nunavut">Nunavut</a></li>
            <li><a data-toggle="tab" href="#Manitoba">Manitoba</a></li>
        </ul> -->
                </div>
            </div>
            <div class="col-md-9">
                <div class="tab-content">
                    <div id="Alberta" class="tab-pane fade in active">
                        <h3>Alberta</h3>
                        <div>
                            <p>Types of Housing/Living Arrangements Covered by the Provincial (<a
                                    href="https://www.alberta.ca/community-housing-property.aspx" target="_blank">
                                    Alberta</a>) Legislation - Residential premises and tenancies of mobile home sites.
                                Mobile homes are covered under the Mobile Home Sites Tenancies Act, as opposed to the
                                Residential Tenancies Act. Residential premises as well as mobile home rentals is listed
                                in the Residential Tenancies Act. Mobile home site rentals are also covered separately
                                in the Mobile Home Sites Tenancies Act, instead of the Residential Tenancies Act.</p>
                            <p></p>
                            <p><strong class="black-color">Exclusions: </strong>There are a few exceptions to The
                                Residential Tenancies Act, including a mobile home site; premises used for the sole
                                purpose of conducting business with living accommodation attached and rented under a
                                single agreement; rooms located in the immediate living area of the landlord, provided
                                the landlord actually resides in the area; a hotel, motel, motor hotel, resort, lodge or
                                tourist camp, a cottage or cabin on a campground or trailer park, tourist home, bed and
                                breakfast business or farm vacation home, if a person lives there for any less than six
                                consecutive months; a tenancy agreement between an educational institution acting as a
                                landlord and a student of the institution acting as a tenant if the tenant does possess
                                a self-contained dwelling; a nursing home according to the Nursing Homes Act, a senior
                                citizens lodge run by the Government of Alberta; a social care facility licensed under
                                the Social Care Facilities Licensing Act, any type of supportive living accommodation
                                licensed under the Supportive Living Accommodation Act; an institution of corrections,
                                or any other prescribed premises.</p>
                            <p>
                                Below is the list of major Alberta cities. Please review the local city bylaws with
                                regards to renting/hosting.
                            </p>
                            <ul class="icon-list">
                                <li><a href="https://www.calgary.ca/business-economy/csps/abs/short-term-rentals-and-lodging-houses.html"
                                        target="_blank">Calgary</a></li>
                                <li><a href="https://www.alberta.ca/landlords-tenants.aspx" target="_blank">Edmonton</a>
                                </li>
                                <li><a href="https://www.reddeer.ca/city-government/bylaws/" target="_blank">Red
                                        Deer</a></li>
                                <li><a href="https://www.lethbridgelegalguidance.ca/landlord-tenant-law-2/"
                                        target="_blank">Lethbridge</a></li>
                                <li><a href="https://stalbert.ca/cosa/bylaws/" target="_blank">St. Albert</a></li>
                                <li><a href="https://www.mhlhc.ca/landlord-tenant-law/" target="_blank">Medicine Hat</a>
                                </li>
                                <li><a href="https://www.gplg.ca/landlord-tenant-law/" target="_blank">Grande
                                        Prairie</a></li>





                            </ul>

                        </div>
                    </div>
                    <div id="british-columbia" class="tab-pane fade">
                        <h3>British Columbia</h3>
                        <div>
                            <p>In British Columbia, there is rent control. Landlords are required to use the approved
                                form “Notice of Rent Increase” and provide the tenant with a period of three month's
                                notice when increasing their rent. Tenants cannot dispute the rent increase unless it
                                exceeds the allowable amount.
                            </p>
                            <p> Below is the list of major British Columbia cities. Please review the local city bylaws
                                with regards to renting/hosting.
                            </p>
                            <p></p>

                            <ul class="icon-list">
                                <li><a href="https://vancouver.ca/doing-business/short-term-rentals.aspx"
                                        target="_blank">Vancouver</a></li>
                                <li><a href="https://www.surrey.ca/city-government/bylaws" target="_blank">Surrey</a>
                                </li>
                                <li><a href="https://www.burnaby.ca/City-Services/Building/Secondary-Suite-Program.html"
                                        target="_blank">Burnaby</a></li>
                                <li><a href="https://www.richmond.ca/safety/property/resident/residentialrentals.htm"
                                        target="_blank">Richmond</a></li>
                                <li><a href="https://www.abbotsford.ca/business-development/building-permits/secondary-suites"
                                        target="_blank">Abbotsford</a></li>
                                <li><a href="https://www.coquitlam.ca/371/Secondary-Suites"
                                        target="_blank">Coquitlam</a></li>
                                <li><a href="https://www.kelowna.ca/business-services/permits-licences/short-term-rentals"
                                        target="_blank">Kelowna</a></li>

                            </ul>

                        </div>
                    </div>
                    <div id="Manitoba" class="tab-pane fade">
                        <h3>Manitoba</h3>
                        <ul class="icon-list">
                            <li><a href="https://www.gov.mb.ca/cca/rtb/" target="_blank">Residential Tenancies
                                    Branch</a></li>
                        </ul>
                        <div>
                            <p>Every condominium corporation has a declaration and bylaws that unit owners must follow.
                                The declaration and bylaws list the rights and responsibilities of the corporation and
                                the unit owners. A tenant who lives in a rental unit that is a condominium must also
                                comply with the declaration and bylaws and any rules. When a tenant moves in, a unit
                                owner/landlord must give them a copy of each provision of the declaration with which the
                                tenant must comply and a copy of the bylaws and rules.</p>
                            <p>Below is the list of major Manitoba cities. Please review the local city bylaws with
                                regards to renting/hosting.</p>
                            <p></p>

                            <ul class="icon-list">
                                <li><a href="https://www.winnipeg.ca/interhom/" target="_blank">Winnipeg</a></li>
                                <li><a href="https://www.brandon.ca/by-laws/view-by-laws" target="_blank">Brandon</a>
                                </li>
                                <li><a href="https://www.steinbach.ca/city-hall/by-laws/" target="_blank">Steinbach</a>
                                </li>
                                <li><a href="https://www.thompson.ca/p/tenant-landowner-rights"
                                        target="_blank">Thompson</a></li>
                                <li><a href="https://www.townofriverview.ca/" target="_blank">Portage La Prairie</a>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div id="new-brunswick" class="tab-pane fade">
                        <h3>New Brunswick</h3>

                        <div>
                            <p>There are no rent controls in <a href="https://www2.gnb.ca/" target="_blank">New
                                    Brunswick</a>. In a month-to-month tenancy, a total of two month's notice is
                                required to allow the tenant to be able to give a month's notice to end the rental
                                agreement</p>
                            <p>Below is the list of major New Brunswick cities. Please review the local city bylaws with
                                regards to renting/hosting.</p>
                            <p></p>

                            <ul class="icon-list">
                                <li><a href="https://www.moncton.ca/" target="_blank">Moncton</a></li>

                                <li><a href="https://saintjohn.ca/en" target="_blank">Saint John</a></li>

                                <li><a href="https://www.fredericton.ca/en" target="_blank">Fredericton</a></li>

                                <li><a href="https://www.dieppe.ca/en/" target="_blank">Dieppe</a></li>

                                <li><a href="https://www.townofriverview.ca/" target="_blank">Riverview</a></li>
                            </ul>

                        </div>
                    </div>
                    <div id="labrador" class="tab-pane fade">
                        <h3>Newfoundland and Labrador</h3>
                        <ul class="icon-list">
                            <li><a href="https://www.gov.nl.ca/dgsnl/landlord/" target="_blank">Landlord & Tenant</a>
                            </li>
                        </ul>
                        <div>
                            <p>The Residential Tenancies Act (RTA) defines the rental agreement as a written, oral or
                                implied agreement between a landlord and a tenant in which the tenant is granted the
                                right to use or occupy residential premises on the condition rent is paid. Every rental
                                agreement must include the information required in the standard rental agreement. Where
                                an oral or implied rental agreement is entered into, the landlord is required to provide
                                the tenant with a written form of the oral or implied agreement in a standard rental
                                agreement form</p>
                            <p>Below is the list of major Newfoundland and Labrador cities. Please review the local city
                                bylaws with regards to renting/hosting.</p>
                            <p></p>

                            <ul class="icon-list">
                                <li><a href="http://www.stjohns.ca/" target="_blank">St. John's</a></li>

                                <li><a href="https://www.conceptionbaysouth.ca/" target="_blank">Conception Bay South</a></li>
                                    
                                <li><a href="https://www.mountpearl.ca/" target="_blank">Mount Pearl</a></li>
                                    
                                <li><a href="https://www.paradise.ca/en/index.aspx" target="_blank">Paradise</a></li>
                                    
                                <li><a href="https://www.cornerbrook.com/" target="_blank">Corner Brook</a></li>
                            </ul>

                        </div>
                    </div>
                    <div id="nova-scotia" class="tab-pane fade">
                        <h3>Nova Scotia</h3>
                        <ul class="icon-list">
                            <li><a href="https://beta.novascotia.ca/documents/residential-tenancies-renting-guide-tenants-and-landlords"
                                    target="_blank">Residential Tenancies: renting guide for tenants and landlords</a>
                            </li>
                        </ul>
                        <div>
                            <p>Once a rental contract, called a lease, has been signed, tenants and landlords have
                                rights and responsibilities. These rights and responsibilities are set out in the
                                Residential Tenancies Act. Whether you are a tenant or a landlord, reading this guide
                                can help you have a positive renting experience.</p>
                            <p>Below is the list of major Nova Scotia cities. Please review the local city bylaws with
                                regards to renting/hosting.</p>
                            <p></p>

                            <ul class="icon-list">
                                <li><a href="https://www.halifax.ca/" target="_blank">Halifax</a></li>

                                <li><a  href="https://www.cbrm.ns.ca/" target="_blank">Cape Breton</a></li>
                                    
                                <li><a  href="https://www.truro.ca/" target="_blank">Truro</a></li>
                                    
                                <li><a  href="https://www.amherst.ca/" target="_blank">Amherst</a></li>
                                    
                                <li><a href="http://www.newglasgow.ca/index.php" target="_blank">New Glasgow</a></li>
                            </ul>

                        </div>
                    </div>
                    <div id="ontario" class="tab-pane fade">
                        <h3>Ontario</h3>

                        <div>
                            <p>In Ontario, home-sharing is one of the fastest growing sectors in the sharing economy.
                                The growth of <a
                                    href="https://www.ontario.ca/page/home-sharing-guide-ontario-municipalities"
                                    target="_blank">home-sharing in Ontario</a> and around the world has been driven by
                                consumers looking for greater choices, flexibility and lower costs, and the opportunity
                                to earn extra income for hosts.</p>
                            <p>There is no consensus definition of home-sharing, but it is generally understood to refer
                                to individuals renting out their residence, or part of their residence, for short
                                periods of time through internet-based platforms. </p>
                            <p>
                                Below is the list of major Ontario cities. Please review the local city bylaws with
                                regards to renting/hosting.
                            </p>
                            <p></p>

                            <ul class="icon-list">
                                <li><a target="_blank" href="https://www.toronto.ca/community-people/housing-shelter/">Toronto</a></li>

                                <li><a  target="_blank" href="https://ottawa.ca/en/living-ottawa/laws-licences-and-permits/laws/law-z/rental-housing-property-management-law-law-no-2020-255">Ottawa</a></li>
                                    
                                <li><a   target="_blank" href="https://www.brampton.ca/EN/residents/By-Law-Enforcement/Pages/Second%20Units%20and%20Lodging%20Houses.aspx">Brampton</a></li>
                                    
                                <li><a   target="_blank" href="https://www.hamilton.ca/home-property-and-development/rental-properties/by-laws-rental-properties">Hamilton</a></li>
                                    
                                <li><a   target="_blank" href="https://www.mississauga.ca/publication/rental-housing-protection-by-law/">Mississauga</a></li>
                                    
                                <li><a   target="_blank" href="https://london.ca/business-development/residential-rental-unit-licences">London</a></li>
                                    
                                <li><a   target="_blank" href="https://www.markham.ca/wps/portal/home/about/city-hall/bylaws/bylaw-guide-for-homeowners/second-suites">Markham</a></li>
                                    
                                <li><a   target="_blank" href="https://www.vaughan.ca/cityhall/departments/bclps/str/Pages/default.aspx">Vaughan</a></li>
                                    
                                <li><a  target="_blank"  href="https://www.kitchener.ca/en/bylaws-and-enforcement/bylaw-guide.aspx">Kitchener</a></li>
                                    
                                <li><a  target="_blank"  href="https://www.waterloo.ca/en/living/residential-rental-licences.aspx">Waterloo</a></li>
                            </ul>

                        </div>
                    </div>
                    <div id="island" class="tab-pane fade">
                        <h3>Prince Edward Island (PEI)</h3>

                        <div>
                            <p>Furnished, partially furnished or unfurnished residential properties including: any type
                                of house, dwelling, apartment, flat, tenement, room, mobile home and land rented as a
                                mobile home site.</p>
                            <p>Governing or Regulatory Body - <a href="http://www.irac.pe.ca/rental/"
                                    target="_blank">Office of the Director of Residential Rental Property</a></p>
                            <p>
                                Name of Act / Regulations - <a
                                    href="https://www.princeedwardisland.ca/sites/default/files/legislation/R%2613-1G-Rental of Residential Property Act Regulations.pdf"
                                    target="_blank">Rental of Residential Property Act and Regulation</a>
                            </p>
                            <p>
                                Below is the list of major PEI cities. Please review the local city bylaws with regards
                                to renting/hosting.
                            </p>
                            <p></p>

                            <ul class="icon-list">
                                <li><a  target="_blank" href="https://www.charlottetown.ca/cms/one.aspx?portalId=10500387&amp;pageId=16539166">Charlottetown</a></li>

                                <li><a   target="_blank" href="https://www.summerside.ca/official_documents_and_data/bylaws">Summerside</a></li>
                                    
                                <li><a   target="_blank" href="https://www.townofstratford.ca/cms/One.aspx?portalId=11992863&amp;pageId=13660236">Stratford</a></li>
                                    
                                <li><a   target="_blank" href="https://cornwallpe.ca/town-hall/bylaws/">Cornwall</a></li>
                                    
                                <li><a   target="_blank" href="https://threeriverspei.com/bylaws/">Three Rivers</a></li>
                            </ul>

                        </div>
                    </div>
                    <div id="quebec" class="tab-pane fade">
                        <h3>Quebec</h3>

                        <div>
                            <p><a href="https://www.quebec.ca/en/homes-and-housing/renting" target="_blank">Quebec</a>
                                follows civil law, which differs from the common law followed in other jurisdictions in
                                Canada. Both landlord and tenant have to abide by a certain number of rules about access
                                to the dwelling and visiting the premises (when the dwelling is for rent or because work
                                needs to be done, for instance).</p>
                            <p>
                                Below is the list of major Quebec cities. Please review the local city bylaws with
                                regards to renting/hosting.
                            </p>

                            <p></p>

                            <ul class="icon-list">
                                <li><a target="_blank" href="https://educaloi.qc.ca/en/web-guide/rental-housing/">Montreal</a></li>

                                <li><a target="_blank"  href="https://www.quebec.ca/en/homes-and-housing/renting/rights-and-obligations-of-the-lessor-and-lessee">Québec</a></li>
                                    
                                <li><a target="_blank"  href="https://www.gatineau.ca/portail/default.aspx?c=en-CA&amp;p=guichet_municipal/reglements_municipaux">Gatineau</a></li>
                                    
                                <li><a target="_blank"  href="https://www.sherbrooke.ca/en/municipal-life/municipal-by-laws/by-laws-explained">Sherbrooke</a></li>
                            </ul>

                        </div>
                    </div>
                    <div id="saskatchewan" class="tab-pane fade">
                        <h3>Saskatchewan</h3>

                        <div>
                            <p><a href="https://publications.saskatchewan.ca/api/v1/products/23011/formats/29464/download"
                                    target="_blank">The Residential Tenancies Act, 2006</a> and <a
                                    href="https://publications.saskatchewan.ca/api/v1/products/23014/formats/29469/download"
                                    target="_blank">The Residential Tenancies Regulations, 2007</a> balances the needs
                                of tenants for safe, secure and habitable living accommodations, and the needs of
                                landlords to conduct a viable business and protect their property investment.</p>
                            <p>
                                Below is the list of major Saskatchewan cities. Please review the local city bylaws with
                                regards to renting/hosting.
                            </p>

                            <p></p>

                            <ul class="icon-list">
                                <li><a target="_blank"   href="https://www.saskatoon.ca/city-hall/city-bylaws-policies">Saskatoon</a></li>

                                <li><a  target="_blank"   href="https://www.regina.ca/home-property/safety-emergencies/fire/prevention-regulations/rental-properties/">Regina</a></li>
                                    
                                <li><a  target="_blank"   href="https://www.citypa.ca/Modules/Bylaws/Bylaw/Search">Prince Albert</a></li>
                                    
                                <li><a  target="_blank"   href="https://moosejaw.ca/bylaws/">Moose Jaw</a></li>
                                    
                                <li><a  target="_blank"   href="http://www.swiftcurrent.ca/i-want-to/learn-about-city-services/bylaw-enforcement">Swift Current</a></li>
                            </ul>

                        </div>
                    </div>
                    <div id="territories" class="tab-pane fade">
                        <h3>Northwest Territories</h3>

                        <div>
                            <p><a href="https://www.justice.gov.nt.ca/en/rental-agreements/" target="_blank">Rental
                                    agreements</a></p>
                            <p>


                                The rules for renting property in the Northwest Territories are governed by the <a target="_blank" href="https://www.justice.gov.nt.ca/en/files/legislation/residential-tenancies/residential-tenancies.a.pdf">Residential Tenancies Act</a> and the <a target="_blank" href="https://www.justice.gov.nt.ca/en/files/legislation/residential-tenancies/residential-tenancies.r1.pdf">Residential Tenancies Regulations</a>. The act appoints a <a target="_blank" href="https://www.justice.gov.nt.ca/en/boards-agencies/rental-office/">rental officer</a> to enforce the act, provide the public with information, and <a target="_blank" href="https://www.justice.gov.nt.ca/en/rental-agreement-dispute-resolution/">resolve disputes</a> between landlords and tenants who have entered into <a target="_blank" href="https://www.justice.gov.nt.ca/en/rental-agreements/">residential tenancy agreements</a>. <a target="_blank" href="https://www.justice.gov.nt.ca/en/rental-officer-decisions/">Decisions by the rental officer</a> have the same force as a Supreme Court order. The legalization of cannabis will require both landlords and tenants to be aware of how the legislation will affect <a target="_blank" href="https://www.justice.gov.nt.ca/en/cannabis-tenants-and-landlords/">the usage of cannabis in their residences</a>.

                            </p>

                            <p></p>



                        </div>
                    </div>
                    <div id="nunavut" class="tab-pane fade">
                        <h3>Nunavut</h3>

                        <div>
                            <p>The landlord may rent the premises to others, but they are required to keep the rent for the new tenant at the same level stated in the notice. The <a href="https://www.gov.nu.ca/"  target="_blank" >Nunavut</a> Housing Corporation is the exception to this rule, as notification of a rent increase is not needed for Public Housing Units.</p>

                            <p></p>

                        </div>
                    </div>
                    <div id="yukon" class="tab-pane fade">
                        <h3>Yukon</h3>
                        <a href="https://yukon.ca/en/residential-landlord-and-tenant-handbook"
                            target="_blank">Residential landlord and tenant handbook</a>
                        <div>
                            <p>The <a
                                    href="https://yukon.ca/sites/yukon.ca/files/cs/cs-residential-landlord-tenant-handbook.pdf"
                                    target="_blank">Residential Landlord and Tenant Act</a> applies to:</p>

                            <p></p>
                            <ul>
                                <li>residential properties (including mobile homes and mobile home sites);</li>
                                <li>properties owned by housing agencies (Yukon Housing Corporation, Kwanlin Dün First
                                    Nation and Grey Mountain Housing Society); and</li>
                                <li>hotel, motel or tourist establishment accommodation over six months’ duration.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>