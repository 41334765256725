<section class="make-us-special-wrapp gap-top">
    <div class="text-center">
        <h3 class="NohightlightedTag">What makes us special</h3>
        <div class="row make-us-special-content-wrapp gap-top-70">
            <div class="col-md-4 make-us-special-block">
                <div class="make-us-special-image-block">
                    <img src="./assets/images/mus-1.svg" alt="">
                </div>
                <h3 class="NohightlightedTag">Security checks</h3>
                <p>Industry leading Identity verification</p>
            </div>
            <div class="col-md-4 make-us-special-block">
                <div class="make-us-special-image-block">
                    <img src="./assets/images/mus-2.svg" alt="">
                </div>
                <h3 class="NohightlightedTag">Easy booking</h3>
                <p>Flexible rental terms and calendar based booking</p>
            </div>
            <div class="col-md-4 make-us-special-block">
                <div class="make-us-special-image-block">
                    <img src="./assets/images/mus-3.svg" alt="">
                </div>
                <h3 class="NohightlightedTag">Support</h3>
                <p>Support for any issues during your rental period</p>
            </div>
            <!-- <div class="col-md-3 make-us-special-block">
              <div class="make-us-special-image-block">
                  <img src="./assets/images/mus-4.svg" alt="">
              </div>
              <h3>Personal profiles</h3>
              <p>Get a snapshot of who lives in the home and their lifestyle</p>
          </div> -->
        </div>
    </div>
</section>