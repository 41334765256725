<section class="address-slide-view gap-50 position-relative">
    <img src="../assets/images/banner-become.png" class="img-fluid" />

    <div class="container  banner-title ">

        <div class="row">
            <div class="col-md-12">
                <h1 class="banner-over-text">Become a Partner</h1>
            </div>


        </div>
    </div>

</section>

<section class="pb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>Partners</h2>
                <p>Started in April 2019 by two former colleagues with over forty years of industry experience between
                    them, PodsLiving provides access to reasonably-priced, comfortable and modern living space for
                    travellers, professionals and students.</p>
            </div>
        </div>
        <div class="row m-0 mt-5 bg1 align-items-center">
            <div class="col-sm-6 p-0">
                <div class="center-div">
                    <h2 class="mb-4 font-w-normal">Real State Agents / Brokers</h2>
                    <p>If you are a real estate agent or broker and would like to become a PodsLiving partner, please
                        register and we can provide insights on how partnering with us can help increase your business
                        and efficiencies.</p>
                    <a href="javascript:void(0);" class="podsliving-button" data-toggle="modal"
                        data-target="#Real_State_Agents">Register Now</a>
                    <!-- </div> -->

                </div>
            </div>
            <div class="col-sm-6 p-0">
                <img src="../assets/images/th1.jpg" alt="" class="img-fluid" />
            </div>
        </div>
        <div class="row m-0 mt-5 bg1 align-items-center">
            <div class="col-sm-6 p-0">
                <img src="../assets/images/th2.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-sm-6 p-0">
                <div class="center-div">
                    <h2 class="mb-4 font-w-normal">Insurance Agent</h2>
                    <p>At PodsLiving we value long-term relationships and partnerships with a view to capitalize on the
                        benefits of mutual growth. We strive to strike strategic partnerships and alliances with you.
                        Please register and we can provide insights
                        on how partnering with us can help increase your business.</p>
                    <a href="javascript:void(0);" class="podsliving-button" data-toggle="modal"
                        data-target="#Insurance_Agents">Register Now</a>
                    <!-- </div> -->

                </div>
            </div>

        </div>
        <div class="row m-0 mt-5 bg1 align-items-center">
            <div class="col-sm-6 p-0">
                <div class="center-div">
                    <h2 class="mb-4 font-w-normal">Lawyers</h2>
                    <p>At PodsLiving we value long-term relationships and partnerships with a view to capitalize on the
                        benefits of mutual growth. We strive to strike strategic partnerships and alliances with you.
                        Please register and we can provide insights
                        on how partnering with us can help increase your business.</p>
                    <a href="javascript:void(0);" class="podsliving-button" data-toggle="modal"
                        data-target="#Insurance_Agents">Register Now</a>
                    <!-- </div> -->

                </div>
            </div>
            <div class="col-sm-6 p-0">
                <img src="../assets/images/th3.jpg" alt="" class="img-fluid" />
            </div>
        </div>

    </div>
</section>
<section class="mt-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>Service Providers</h2>
                <p>Started in April 2019 by two former colleagues with over forty years of industry experience between
                    them, PodsLiving provides access to reasonably-priced, comfortable and modern living space for
                    travellers, professionals and students.</p>
            </div>
        </div>
        <div class="row m-0 mt-5 bg1 align-items-center">

            <div class="col-sm-6 p-0">
                <img src="../assets/images/th4.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-sm-6 p-0">
                <div class="center-div">
                    <h2 class="mb-4 font-w-normal">Cleaning Services</h2>
                    <p>At PodsLiving we value long-term relationships and partnerships with a view to capitalize on the
                        benefits of mutual growth. We strive to strike strategic partnerships and alliances with you.
                        Please register and we can provide insights
                        on how partnering with us can help increase your business.</p>
                    <a href="javascript:void(0);" class="podsliving-button" data-toggle="modal"
                        data-target="#Cleaning_service">Register Now</a>
                    <!-- </div> -->

                </div>
            </div>
        </div>
        <div class="row m-0 mt-5 bg1 align-items-center">

            <div class="col-sm-6 p-0">
                <div class="center-div">
                    <h2 class="mb-4 font-w-normal">Electrician</h2>
                    <p>If you are a real estate agent or broker and would like to become a PodsLiving partner, please
                        register and we can provide insights on how partnering with us can help increase your business
                        and efficiencies.</p>
                    <a href="javascript:void(0);" class="podsliving-button" data-toggle="modal"
                        data-target="#Electrician_service">Register Now</a>
                    <!-- </div> -->

                </div>
            </div>
            <div class="col-sm-6 p-0">
                <img src="../assets/images/th5.jpg" alt="" class="img-fluid" />
            </div>

        </div>
        <div class="row m-0 mt-5 bg1 align-items-center">
            <div class="col-sm-6 p-0">
                <img src="../assets/images/th6.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-sm-6 p-0">
                <div class="center-div">
                    <h2 class="mb-4 font-w-normal">Plumber</h2>
                    <p>At PodsLiving we value long-term relationships and partnerships with a view to capitalize on the
                        benefits of mutual growth. We strive to strike strategic partnerships and alliances with you.
                        Please register and we can provide insights
                        on how partnering with us can help increase your business.</p>
                    <a href="javascript:void(0);" class="podsliving-button" data-toggle="modal"
                        data-target="#Plumber_service">Register Now</a>
                    <!-- </div> -->

                </div>
            </div>

        </div>

    </div>
</section>
<div class="modal fade" id="Real_State_Agents" tabindex="-1" role="dialog" aria-labelledby="login_register"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="login-modal-wrapp">
                    <a href="javascript:void(0);" data-dismiss="modal" aria-label="Close" class="close-button"><img
                            src="images/cross.svg" alt=""></a>



                    <a href="javascript:void(0);" class="back-button" id="mobile_no_back__button"><img
                            src="images/back.svg" alt=""></a>
                    <a href="javascript:void(0);" class="back-button" id="get_otp_back_button"><img
                            src="images/back.svg" alt=""></a>
                    <div class="login-modal-image-block">
                        <div class="login-image-content-block">
                            <div class="login-modal-title text-white text-center">
                                Login and enjoy hosting<br>& renting experience
                            </div>
                        </div>
                    </div>
                    <div class="login-modal-content-wrapp position-relative">

                        <div id="register_emial_content" class="position-relative w-100">

                            <div class="modal-right-content-block become">
                                <div class="login-modal-content-block">
                                    <div class="login-modal-title">Register - Agent / Broker</div>
                                    <div class="login-modal-form-wrapp">
                                        <form class="ng-invalid ng-dirty ng-touched">
                                            <div class="login-modal-form-wrapp">
                                                <div class="row">
                                                    <div class="col-md-6 form-block">
                                                        <label>First Name<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Enter First Name"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>Last Name<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Enter Last Name" class="">
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>Registering as<span class="red-text">*</span></label>
                                                        <select class="">
                                                            <option value="As Real State Agent"
                                                                class="ng-star-inserted">As Real State Agent</option>
                                                            <option value="As Real State Agent" ng-reflect-value="+91"
                                                                class="ng-star-inserted">As Real State Broker</option>
                                                            <!--                                                                            <option value="+60" ng-reflect-value="+60" class="ng-star-inserted"> +60 </option>-->
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>ID Number<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Insurance ID" class="">
                                                    </div>
                                                    <div class="col-md-6 form-block phone-n-v">
                                                        <label>Phone Number<span class="red-text">*</span></label>
                                                        <div class="row p-col-4">
                                                            <div class="col-md-4 w-4 pr-0">
                                                                <select class="">
                                                                    <option value="+1" class="ng-star-inserted"> +1
                                                                    </option>
                                                                    <option value="+91" ng-reflect-value="+91"
                                                                        class="ng-star-inserted"> +91 </option>
                                                                    <option value="+60" ng-reflect-value="+60"
                                                                        class="ng-star-inserted"> +60 </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-8 w-8">
                                                                <input type="text" formcontrolname="phoneNumber"
                                                                    placeholder="Enter phone no." class="form-control"
                                                                    ng-reflect-name="phoneNumber">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>Email Address<span class="red-text">*</span></label>
                                                        <input type="email" placeholder="Enter Email Address"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-6 form-block phone-n-v">
                                                        <label>Brokerage Name<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Brokrage Name"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>Contact Person Name<span
                                                                class="red-text">*</span></label>
                                                        <input type="text" placeholder="Brokrage Name"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-6 form-block phone-n-v">
                                                        <label>Brokerage Address<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Brokrage Address"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>Contact Number<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Contact Number"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-12 form-block mb-0">
                                                        <div class="checkbox check-2-line-block">
                                                            <input type="checkbox" id="agree_register_form">
                                                            <label for="agree_register_form">By registering or logging
                                                                in to {{domain.text}}  you agree to all our <a
                                                                    [routerLink]="['/policies/terms-and-conditions']"
                                                                    target="_blank" class="blue-text text-bold">legal
                                                                    terms and conditions and policies</a>.
                                                            </label>



                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 text-right">
                                                        <a href="javascript:void(0);"
                                                            class="podsliving-button  m-width-auto"
                                                            onclick="">Register</a>
                                                    </div>


                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>





                </div>



            </div>


        </div>
    </div>
</div>
<div class="modal fade" id="Real_State_Agents" tabindex="-1" role="dialog" aria-labelledby="login_register"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="login-modal-wrapp">
                    <a href="javascript:void(0);" data-dismiss="modal" aria-label="Close" class="close-button"><img
                            src="images/cross.svg" alt=""></a>



                    <a href="javascript:void(0);" class="back-button" id="mobile_no_back__button"><img
                            src="images/back.svg" alt=""></a>
                    <a href="javascript:void(0);" class="back-button" id="get_otp_back_button"><img
                            src="images/back.svg" alt=""></a>
                    <div class="login-modal-image-block">
                        <div class="login-image-content-block">
                            <div class="login-modal-title text-white text-center">
                                Login and enjoy hosting<br>& renting experience
                            </div>
                        </div>
                    </div>
                    <div class="login-modal-content-wrapp position-relative">

                        <div id="register_emial_content" class="position-relative w-100">

                            <div class="modal-right-content-block become">
                                <div class="login-modal-content-block">
                                    <div class="login-modal-title">Register - Agent / Broker</div>
                                    <div class="login-modal-form-wrapp">
                                        <form class="ng-invalid ng-dirty ng-touched">
                                            <div class="login-modal-form-wrapp">
                                                <div class="row">
                                                    <div class="col-md-6 form-block">
                                                        <label>First Name<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Enter First Name"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>Last Name<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Enter Last Name" class="">
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>Registering as<span class="red-text">*</span></label>
                                                        <select class="">
                                                            <option value="As Real State Agent"
                                                                class="ng-star-inserted">As Real State Agent</option>
                                                            <option value="As Real State Agent" ng-reflect-value="+91"
                                                                class="ng-star-inserted">As Real State Broker</option>
                                                            <!--                                                                            <option value="+60" ng-reflect-value="+60" class="ng-star-inserted"> +60 </option>-->
                                                        </select>
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>ID Number<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Insurance ID" class="">
                                                    </div>
                                                    <div class="col-md-6 form-block phone-n-v">
                                                        <label>Phone Number<span class="red-text">*</span></label>
                                                        <div class="row p-col-4">
                                                            <div class="col-md-4 w-4 pr-0">
                                                                <select class="">
                                                                    <option value="+1" class="ng-star-inserted"> +1
                                                                    </option>
                                                                    <option value="+91" ng-reflect-value="+91"
                                                                        class="ng-star-inserted"> +91 </option>
                                                                    <option value="+60" ng-reflect-value="+60"
                                                                        class="ng-star-inserted"> +60 </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-8 w-8">
                                                                <input type="text" formcontrolname="phoneNumber"
                                                                    placeholder="Enter phone no." class="form-control"
                                                                    ng-reflect-name="phoneNumber">

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>Email Address<span class="red-text">*</span></label>
                                                        <input type="email" placeholder="Enter Email Address"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-6 form-block phone-n-v">
                                                        <label>Brokerage Name<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Brokrage Name"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>Contact Person Name<span
                                                                class="red-text">*</span></label>
                                                        <input type="text" placeholder="Brokrage Name"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-6 form-block phone-n-v">
                                                        <label>Brokerage Address<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Brokrage Address"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-6 form-block">
                                                        <label>Contact Number<span class="red-text">*</span></label>
                                                        <input type="text" placeholder="Contact Number"
                                                            class="form-control ">
                                                    </div>
                                                    <div class="col-md-12 form-block mb-0">
                                                        <div class="checkbox check-2-line-block">
                                                            <input type="checkbox" id="agree_register_form">
                                                            <label for="agree_register_form">By registering or logging
                                                                in to {{domain.text}}  you agree to all our <a
                                                                    [routerLink]="['/policies/terms-and-conditions']"
                                                                    target="_blank" class="blue-text text-bold">legal
                                                                    terms and conditions and policies</a>.
                                                            </label>



                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 text-right">
                                                        <a href="javascript:void(0);"
                                                            class="podsliving-button  m-width-auto"
                                                            onclick="">Register</a>
                                                    </div>


                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>





                </div>



            </div>


        </div>
    </div>
</div>
<div class="modal fade" id="Insurance_Agents" tabindex="-1" role="dialog" aria-labelledby="Insurance_Agents"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body p-5">
                <div class="login-modal-wrapp">
                    <a href="javascript:void(0);" data-dismiss="modal" aria-label="Close" class="close-button"><img
                            src="images/cross.svg" alt=""></a>
                    <div class="row">
                        <div class="col-md-12">
                            <h2>To Avail PodsLiving end-to-end rental solution services, please provide following
                                informations.</h2>
                            <div class="border-block-y">
                                <div class="text-bold">Please Select Type of Service Required</div>
                                <div class="row mt-3">
                                    <div class="col-md-4 checkbox-group">
                                        <input type="checkbox" id="entire_place">
                                        <label for="entire_place">Listing Management Services</label>
                                    </div>
                                    <div class="col-md-4 checkbox-group">
                                        <input type="checkbox" id="private_room">
                                        <label for="private_room">Maintenece Services</label>
                                    </div>

                                </div>
                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <div class="form-block">
                                            <label>First Name</label>
                                            <input type="text" value="First Name">
                                        </div>
                                        <div class="form-block">
                                            <label>Last Name</label>
                                            <input type="text" value="Last Name">
                                        </div>
                                        <div class="form-block">
                                            <label>Email Address</label>
                                            <input type="text" value="Email Address">
                                        </div>
                                        <div class="form-block">
                                            <label>Phone</label>
                                            <input type="text" value="Phone">
                                        </div>
                                        <div class="form-block">
                                            <label>Property Description</label>
                                            <textarea class="form-control min-h-auto" rows="3"
                                                placeholder="Describe your Property Details"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <h3>Property Details</h3>
                                        <div class="form-block">
                                            <label>Address Line 1</label>
                                            <input type="text" placeholder="Address line 1">
                                        </div>
                                        <div class="form-block">
                                            <label>Address Line 2</label>
                                            <input type="text" placeholder="Address line 1">
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-block">
                                                    <label>City</label>
                                                    <input type="text" placeholder="City">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-block">
                                                    <label>Province / State</label>
                                                    <input type="text" placeholder="State">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-block">
                                                    <label>Zip / Postal Code</label>
                                                    <input type="text" placeholder="Zip / Postal Code">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-block">
                                                    <label>Country</label>
                                                    <input type="text" placeholder="Country">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-block">
                                                    <label>Rent Expected Per month($)</label>
                                                    <input type="text" placeholder="1500">
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-md-12 text-right">
                                        <a href="javascript:void(0);" class="podsliving-button  m-width-auto"
                                            onclick="">Submit</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="Plumber_service" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-llg" role="document">
        <div class="modal-content">
            <div class="modal-body p-5">
                <div class="login-modal-wrapp">
                    <a href="javascript:void(0);" data-dismiss="modal" aria-label="Close" class="close-button"><img
                            src="images/cross.svg" alt=""></a>
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Register as Plumbers</h2>

                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="row gap-6">

                                        <div class="col-md-6 form-block">
                                            <label>First Name</label>
                                            <input type="text" placeholder="First Name" class="form-control">
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Last Name</label>
                                            <input type="text" placeholder="Last Name" class="form-control">
                                        </div>
                                        <div class="col-md-12 form-block">
                                            <label>Phone Number</label>
                                            <div class="row multi-form-block">
                                                <div class="col-md-3">
                                                    <select>
                                                        <option value="">+01</option>
                                                        <option value="">+01</option>
                                                        <option value="">+01</option>
                                                        <option value="">+01</option>
                                                        <option value="">+01</option>
                                                    </select>

                                                </div>
                                                <div class="col-md-9">
                                                    <input type="number" value="9582432124">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Email Address</label>
                                            <input type="email" placeholder="Email Address">
                                        </div>

                                        <div class="col-md-6 form-block">
                                            <label>ID Proof</label>
                                            <select style="display: none;">
                                                <option value="">Driving License</option>

                                            </select>
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>ID Proof Number</label>
                                            <input type="text" placeholder="ID Proof Number">
                                        </div>
                                        <div class="col-md-6 form-block file-upload-only-name">
                                            <label>Upload Govt. Issued ID proof</label>
                                            <div class="file-upload">
                                                <div class="file-upload-wrapper" data-text="Browse">
                                                    <input name="file-upload-field" type="file"
                                                        class="file-upload-field" value="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Are You Certified?</label>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-subs">
                                                        <div class="form-subs-lable-block">
                                                            <label>
                                                                <input type="radio" id="vaccinated_1" name="gender2"
                                                                    value="yes" checked="">
                                                                <span class="btn-v">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div class="form-subs-lable-block">
                                                            <label>
                                                                <input type="radio" id="vaccinated_2" name="gender2"
                                                                    value="no">
                                                                <span class="btn-v">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Certification Number</label>
                                            <input type="text" placeholder="Certification Numberr">
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Prior Experience</label>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-subs">
                                                        <div class="form-subs-lable-block">
                                                            <label>
                                                                <input type="radio" id="vaccinated_11" name="gender22"
                                                                    value="yes" checked="">
                                                                <span class="btn-v">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div class="form-subs-lable-block">
                                                            <label>
                                                                <input type="radio" id="vaccinated_21" name="gender22"
                                                                    value="no">
                                                                <span class="btn-v">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>How Many Months?</label>
                                            <input type="text" placeholder="51 - 100 months">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6 form-block">
                                            <label>Your Hourly Rate (in $) per hour</label>
                                            <input type="text" placeholder="$100">
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Additional Hour charge (in $) per hour</label>
                                            <input type="text" placeholder="$50">
                                        </div>
                                        <div class="form-block col-md-12">
                                            <label>Min Hour Commitment</label>
                                            <input type="text" placeholder="1 Hour">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h3>Your Expertise Services</h3>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services1">
                                                <label class="f-w-n" for="Services1">Toilet repair and
                                                    replacement</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services2">
                                                <label class="f-w-n" for="Services2">Faucet repair and
                                                    replacement</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services3">
                                                <label class="f-w-n" for="Services1">Installing new fixtures</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services4">
                                                <label class="f-w-n" for="Services2">Laundry tubs and faucets</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services5">
                                                <label class="f-w-n" for="Services1">Toilet repair and
                                                    replacement</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services6">
                                                <label class="f-w-n" for="Services2">Bath and kitchen renovation</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services5">
                                                <label class="f-w-n" for="Services1">Toilet repair and
                                                    replacement</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services6">
                                                <label class="f-w-n" for="Services2">Bath and kitchen renovation</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services5">
                                                <label class="f-w-n" for="Services1">Toilet repair and
                                                    replacement</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services6">
                                                <label class="f-w-n" for="Services2">Bath and kitchen renovation</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services5">
                                                <label class="f-w-n" for="Services1">Toilet repair and
                                                    replacement</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services6">
                                                <label class="f-w-n" for="Services2">Bath and kitchen renovation</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services5">
                                                <label class="f-w-n" for="Services1">Toilet repair and
                                                    replacement</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services6">
                                                <label class="f-w-n" for="Services2">Bath and kitchen renovation</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services5">
                                                <label class="f-w-n" for="Services1">Toilet repair and
                                                    replacement</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services6">
                                                <label class="f-w-n" for="Services2">Bath and kitchen renovation</label>
                                            </div>
                                        </div>
                                        <div class="col-md-8 form-block mb-0">
                                            <div class="checkbox check-2-line-block">
                                                <input type="checkbox" id="agree_register_form">
                                                <label for="agree_register_form">By registering or logging in to
                                                    {{domain.text}}  you agree to all our <a
                                                        [routerLink]="['/policies/terms-and-conditions']"
                                                        target="_blank" class="blue-text text-bold">legal terms and
                                                        conditions and policies</a>.
                                                </label>



                                            </div>
                                        </div>
                                        <div class="col-md-4 text-right">
                                            <a href="javascript:void(0);" class="podsliving-button  m-width-auto"
                                                onclick="">Save</a>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-12 ">
                                    <div class="checkbox check-2-line-block">
                                        <input type="checkbox" id="agree_register_form">
                                        <label for="agree_register_form">By registering or logging in to {{domain.text}} 
                                            you agree to all our <a [routerLink]="['/policies/terms-and-conditions']"
                                                target="_blank" class="blue-text text-bold">legal terms and conditions
                                                and policies</a>.
                                        </label>



                                    </div> <a href="javascript:void(0);" class="podsliving-button  m-width-auto"
                                        onclick="">Submit</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="Electrician_service" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-llg" role="document">
        <div class="modal-content">
            <div class="modal-body p-5">
                <div class="login-modal-wrapp">
                    <a href="javascript:void(0);" data-dismiss="modal" aria-label="Close" class="close-button"><img
                            src="images/cross.svg" alt=""></a>
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Register as Electrician</h2>

                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="row gap-6">

                                        <div class="col-md-6 form-block">
                                            <label>First Name</label>
                                            <input type="text" placeholder="First Name" class="form-control">
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Last Name</label>
                                            <input type="text" placeholder="Last Name" class="form-control">
                                        </div>
                                        <div class="col-md-12 form-block">
                                            <label>Phone Number</label>
                                            <div class="row multi-form-block">
                                                <div class="col-md-3">
                                                    <select>
                                                        <option value="">+01</option>
                                                        <option value="">+01</option>
                                                        <option value="">+01</option>
                                                        <option value="">+01</option>
                                                        <option value="">+01</option>
                                                    </select>

                                                </div>
                                                <div class="col-md-9">
                                                    <input type="number" value="9582432124">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Email Address</label>
                                            <input type="email" placeholder="Email Address">
                                        </div>

                                        <div class="col-md-6 form-block">
                                            <label>ID Proof</label>
                                            <select style="display: none;">
                                                <option value="">Driving License</option>

                                            </select>
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>ID Proof Number</label>
                                            <input type="text" placeholder="ID Proof Number">
                                        </div>
                                        <div class="col-md-6 form-block file-upload-only-name">
                                            <label>Upload Govt. Issued ID proof</label>
                                            <div class="file-upload">
                                                <div class="file-upload-wrapper" data-text="Browse">
                                                    <input name="file-upload-field" type="file"
                                                        class="file-upload-field" value="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Are You Certified?</label>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-subs">
                                                        <div class="form-subs-lable-block">
                                                            <label>
                                                                <input type="radio" id="vaccinated_1" name="gender2"
                                                                    value="yes" checked="">
                                                                <span class="btn-v">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div class="form-subs-lable-block">
                                                            <label>
                                                                <input type="radio" id="vaccinated_2" name="gender2"
                                                                    value="no">
                                                                <span class="btn-v">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Certification Number</label>
                                            <input type="text" placeholder="Certification Numberr">
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Prior Experience</label>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-subs">
                                                        <div class="form-subs-lable-block">
                                                            <label>
                                                                <input type="radio" id="vaccinated_11" name="gender22"
                                                                    value="yes" checked="">
                                                                <span class="btn-v">Yes</span>
                                                            </label>
                                                        </div>
                                                        <div class="form-subs-lable-block">
                                                            <label>
                                                                <input type="radio" id="vaccinated_21" name="gender22"
                                                                    value="no">
                                                                <span class="btn-v">No</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>How Many Months?</label>
                                            <input type="text" placeholder="51 - 100 months">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6 form-block">
                                            <label>Your Hourly Rate (in $) per hour</label>
                                            <input type="text" placeholder="$100">
                                        </div>
                                        <div class="col-md-6 form-block">
                                            <label>Additional Hour charge (in $) per hour</label>
                                            <input type="text" placeholder="$50">
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <h3>Your Expertise Services</h3>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services1">
                                                <label class="f-w-n" for="Services1">Electrical wiring /
                                                    rewiring</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services2">
                                                <label class="f-w-n" for="Services2">Installation/Fitting</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services3">
                                                <label class="f-w-n" for="Services1">Repair</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services4">
                                                <label class="f-w-n" for="Services2">Laundry tubs and faucets</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services5">
                                                <label class="f-w-n" for="Services1">Maintenance/Inspection</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services6">
                                                <label class="f-w-n" for="Services2">Earthing</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12 ">
                                            <div class="checkbox-group">
                                                <input type="checkbox" id="Services5">
                                                <label class="f-w-n" for="Services1">Electrical motor
                                                    winding/repair</label>
                                            </div>
                                        </div>

                                        <div class="col-md-12 form-block mb-0">
                                            <div class="checkbox check-2-line-block">
                                                <input type="checkbox" id="agree_register_form">
                                                <label for="agree_register_form">By registering or logging in to
                                                    {{domain.text}}  you agree to all our <a
                                                        [routerLink]="['/policies/terms-and-conditions']"
                                                        target="_blank" class="blue-text text-bold">legal terms and
                                                        conditions and policies</a>.
                                                </label>



                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="col-md-12 ">
                                    <div class="checkbox check-2-line-block">
                                        <input type="checkbox" id="agree_register_form">
                                        <label for="agree_register_form">By registering or logging in to {{domain.text}} 
                                            you agree to all our <a [routerLink]="['/policies/terms-and-conditions']"
                                                target="_blank" class="blue-text text-bold">legal terms and conditions
                                                and policies</a>.
                                        </label>



                                    </div> <a href="javascript:void(0);" class="podsliving-button  m-width-auto"
                                        onclick="">Submit</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>