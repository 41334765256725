<section class="address-slide-view gap-50 position-relative">
    <img src="../assets/images/cleaning-banner.jpg" class="img-fluid"/>

    <div class="container  banner-title ">
     
      <div class="row">
        <div class="col-md-12">
  <h1 class="banner-over-text"></h1>
        </div>
       
        
      </div>
      </div>
        
  </section>
  <section>
      <div class="container">
<div class="row">
    <div class="col-md-12">
        <h2>PodsLiving end-to-end Rental Solutions Services</h2>
        <p>PodsLiving will provide you following hosting guidance for listing and maintenance services.</p>
    </div>
    </div>
    <div class="row m-0 mt-5 bg1 align-items-center">
        <div class="col-sm-6 p-0">
            <div class="center-div">
                <h2 class="mb-4">Listing Management Services</h2>
                <ul>
                    <li>Describe your property </li>
                    <li>Refine your property picture</li>
                    <li>Promote Listing via additional social media platforms</li>
                </ul>
                <a href="#" class="btn blue-btn">BOOK AN APPOINTMENT</a>
            </div>
        </div>
        <div class="col-sm-6 p-0">
            <img src="../assets/images/f1.jpg" alt="" class="img-fluid"/>
        </div>
    </div>
    <div class="row m-0 mt-5 bg2 align-items-center">
        <div class="col-sm-6 p-0">
            <img src="../assets/images/f1.jpg" alt="" class="img-fluid"/>
        </div>
        <div class="col-sm-6 p-0">
            <div class="center-div">
                <h2 class="mb-4">Management Services</h2>
                <ul>
                    <li>Maintaining properties including minor fixes</li>
                    <li>Arranging for necessary repairs to properties</li>
                    <li>Setting up and adhering to budgets for property maintenance</li>
                    <li>Screening potential tenants</li>
                    <li>Drafting, signing, and renewing leases on your behalf</li>
                    <li>Collecting monthly rents and deposit to your bank</li>
                    <li>Renewal of property lease on your behalf</li>
                </ul>
                <a href="#" class="btn blue-btn">BOOK AN APPOINTMENT</a>
            </div>
        </div>
       
    </div>
    <div class="row m-0 mt-5 bg1 align-items-center">
        <div class="col-sm-6 p-0">
            <div class="center-div">
                <h2 class="mb-4">Need Help To Up-Keep Your Property?</h2>
                <ul>
                    <li>PodsLiving will help you in management of your Property.</li>
                </ul>
                <a href="#" class="btn blue-btn">BOOK AN APPOINTMENT</a>
            </div>
        </div>
        <div class="col-sm-6 p-0">
            <img src="../assets/images/f1.jpg" alt="" class="img-fluid"/>
        </div>
    </div>
    
      </div>
      </section>
      <section class="explore-carousel-wrapp gap-top-70">
          <div class="container">
        <h2>Book an appointment for following services</h2>
        <div class="row mt-4">
            <div class="col-md-4 col-6">
                <div class="img-hv">
                    <a [routerLink]="['/services/plumber']">
                    <img src="../assets/images/plumber-l.jpg" class="img-fluid" alt="" />  
                    <div class="over-text other">Plumber</div>
                </a>
                </div>
            </div>
            <div class="col-md-4 col-6">
                <div class="img-hv"><img src="../assets/images/electrician-l.jpg" class="img-fluid" alt="" />  <div class="over-text other">Electrician</div></div>
            </div>
            <div class="col-md-4 col-6">
                <div class="img-hv"><img src="../assets/images/cleaning-l.jpg" class="img-fluid" alt="" />  <div class="over-text other">Cleaning</div></div>
            </div>
           

        </div>
    </div>
    </section>