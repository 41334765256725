import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactUsComponent } from 'src/shared/component/contact-us/contact-us.component';
import { AuthGuard } from 'src/shared/guards/auth.guard';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { PreloginComponent } from './home/prelogin.component';
import { DetailViewComponent } from './search-result/detail-view/detail-view.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { InformationCenterComponent } from 'src/shared/component/information-center/information-center.component';
import { SearchByComponent } from './search-by/search-by.component';
import { HowItWorksComponent } from 'src/shared/component/how-it-works/how-it-works.component';
import { HowItWorksHostComponent } from 'src/shared/component/how-it-works-host/how-it-works-host.component';
import { HowItWorksPartnerComponent } from 'src/shared/component/how-it-works-partner/how-it-works-partner.component';
import { CareerComponent } from 'src/shared/component/career/career.component';
import { CleaningComponent } from './pages/service-pages/cleaning/cleaning.component';
import { AboutComponent } from 'src/shared/component/about/about.component';
import { FaqComponent } from 'src/shared/component/faq/faq.component';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import { WebinarComponent } from '../shared/webinar/webinar.component';
import { BecomeAPartnerComponent } from 'src/shared/component/become-a-partner/become-a-partner.component';
import { GuestStudentHomeComponent } from './pages/guest-student-home/guest-student-home.component';
import { GuestHomeComponent } from './pages/guest-home/guest-home.component';
import { LongTermDetailViewComponent } from './search-result/long-term-detail-view/long-term-detail-view.component';
import { MobileLoginComponent } from './../shared/component/mobile-login/mobile-login.component';
import { ElectricianComponent } from './pages/service-pages/electrician/electrician.component';
import { FacilityManagementComponent } from './pages/service-pages/facility-management/facility-management.component';
import { PlumberComponent } from './pages/service-pages/plumber/plumber.component';

const routes: Routes = [

  //modules with authentication
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule), canActivate: [AuthGuard] },


  //modules without authentication
  
  { path: 'policies', loadChildren: () => import('src/shared/policies/policies.module').then((m) => m.PoliciesModule) },
  { path: 'properties', loadChildren: () => import('./search-result/search-result.module').then((m) => m.SearchResultModule) },

  //components
  { path: '', component: PreloginComponent, data: { seo: { title: 'Lease Apartments & Houses for Rent in Canada', metaTags: [{ name: 'description', content: 'Pods Living is designed to find out rental apartment, vacation home, studio, condos, commercial property for short/long-term duration at affordable price in Canada.' }] } } },
  { path: 'comingsoon', component: ComingSoonComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contactus', component: ContactUsComponent },
  { path: 'information-center', component: InformationCenterComponent },
  { path: 'career', component: CareerComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'webinar', component: WebinarComponent },
  { path: 'top-destination', pathMatch: 'full', component: SearchByComponent, data: { seo: { title: 'Rental Property in Top Destination | Canada Vacation Home or Apartment', metaTags: [{ name: 'description', content: 'Get the best rental place at top destination place of Canada, either for office meeting or on vacation with family. Full accommodation with proper hygiene in apartment.' }] } }},
 
  { path: 'student', component: GuestStudentHomeComponent, data: { seo: { title: 'Student Accommodation & Housing Services | Living Off Campus', metaTags: [{ name: 'description', content: 'If you are looking for safe & budget friendly off-campus student housing apartment that are close to college or university, you have come to the right place.' }] } }},
  { path: 'traveller', component: GuestHomeComponent, data: { seo: { title: 'Vacation Rental Home for Travelers | Condo Appartment', metaTags: [{ name: 'description', content: 'Are you wondering how to find short-term housing for your next travel assignment? Get incredible rental vacation home experience during extended stay business travel.' }] } }},
  { path: 'professional',component: GuestHomeComponent},
 
  { path: 'university', pathMatch: 'full', component: SearchByComponent, data: { seo: { title: 'Student Accommendation Near Universities | Budget Friendly Rental Apartment', metaTags: [{ name: 'description', content: 'Find the best place for off campus residential property or apartment for students nearby your college or university. Houses and Aapartments in the neighborhood adjacent to the campus.' }] } }},
  { path: 'location', pathMatch: 'full', component: SearchByComponent, data: { seo: { title: 'Lease Property in Popular Cities | Canada Vacation Rentals', metaTags: [{ name: 'description', content: 'Looking for short term or long-term rental property in popular cities of Canada. Apartment, Condos, House, Airbnb for professional or family trip in Canada.' }] } }},
  { path: 'verification-success', pathMatch: 'full', component: EmailVerificationComponent },
  { path: 'verification-failed', pathMatch: 'full', component: EmailVerificationComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'how-it-works-host', component: HowItWorksHostComponent },
  { path: 'how-it-works-partner', component: HowItWorksPartnerComponent },
  { path: 'become-a-partner', component: BecomeAPartnerComponent },
  { path: 'services/cleaning', component: CleaningComponent },
  { path: 'services/electrician', component: ElectricianComponent },
  { path: 'services/plumber', component: PlumberComponent },
  { path: 'services/facility-management', component: FacilityManagementComponent },
  { path: 'property/detail/:slug', component: DetailViewComponent },
  { path: 'property/long-term/detail/:slug', component: LongTermDetailViewComponent },
  { path: 'mlogin', component: MobileLoginComponent },

  //for 404 request
  { path: '**', pathMatch: 'full', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabled'
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
