import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Paginations_Pages } from 'src/app/app.constants';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
  @Input() paginations: any;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  pages: any = [];

  constructor() { }

  ngOnInit(): void {
    Object.assign(this.pages, Paginations_Pages);
  }

  paginationSetup(type: any) {
    if (type == 'P') {
      if (this.paginations.current_page > 1) {
        this.paginations.page = this.paginations.current_page - 1;
        this.submitEvent.emit();        
      }
    }
    else if (type == 'L') {
      if (this.paginations.current_page < this.paginations.totalPage.length) {
        this.paginations.page = this.paginations.current_page + 1;
        this.submitEvent.emit();        
      }
    }
    else if(type == 'page'){
      this.submitEvent.emit();        
    }
    else {
      if (this.paginations.current_page != type) {
        this.paginations.page = type;
        this.submitEvent.emit();        
      }
    }
  }

}
