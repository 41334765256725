<section class="banner header-gap gap-50 position-relative">
    <img src="../assets/images/faq.jpg" class="w-100 img-fluid" alt="" />
    <div class="container  banner-title ">

        <div class="row">
            <div class="col-md-12">
                <h1 class="banner-over-text">FREQUENTLY ASKED QUESTIONS</h1>
            </div>


        </div>
    </div>
</section>
<section>
    <div class="container">
      
        <ul class="nav nav-tabs nv tabs-scroll-wrapp">
          <li><a data-toggle="tab" href="#Property_Booking"  class="active">Property Booking</a></li>
          <li><a data-toggle="tab" href="#Property_Listing">Property Listing</a></li>
          <!-- <li><a data-toggle="tab" href="#Partners_Services">Partners Services</a></li>
          <li><a data-toggle="tab" href="#Value_Added_Services">Value Added Services</a></li> -->
          <li><a data-toggle="tab" href="#Pricing_Management">Pricing Management</a></li>
          <li><a data-toggle="tab" href="#Common_Questions">Common Question</a></li>
        </ul>
      
        <div class="tab-content">
          <div id="Property_Booking" class="tab-pane fade in active">
            <div class="panel-group" id="accordion">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse1"> What is your booking option?</a>
                    </h4>
                  </div>
                  <div id="collapse1" class="panel-collapse collapse in">
                    <div class="panel-body">You can book a short-term vacation rental and a long-term leasing property at PodsLiving.ca/properties</div>
                  </div>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse2">Can I book property on PodsLiving? </a>
                    </h4>
                  </div>
                  <div id="collapse2" class="panel-collapse collapse">
                    <div class="panel-body">PodsLiving offers vacation rental booking to their guests around the world </div>
                  </div>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse3">Can I pay partial amount to book a property?</a>
                    </h4>
                  </div>
                  <div id="collapse3" class="panel-collapse collapse">
                    <div class="panel-body">You can do that PodsLiving offers book now pay later facility to their guests </div>
                  </div>
                </div>
            
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse4">Can I search near university? </a>
                    </h4>
                  </div>
                  <div id="collapse4" class="panel-collapse collapse">
                    <div class="panel-body">You can as PodsLiving offers wide area of search for their guests</div>
                  </div>
                </div>
            
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse5"> Can I get my rental agreement notarized? </a>
                    </h4>
                  </div>
                  <div id="collapse5" class="panel-collapse collapse">
                    <div class="panel-body">You can notarize your rental agreement PodsLiving</div>
                  </div>
                </div>
            
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse6">What type of property I can book?</a>
                    </h4>
                  </div>
                  <div id="collapse6" class="panel-collapse collapse">
                    <div class="panel-body">You can book short term rental and long term lease property at PodsLiving to know more visit us at PodsLiving.ca/properties</div>
                  </div>
                </div>
            
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse7">What are cleaning charges for short term?</a>
                    </h4>
                  </div>
                  <div id="collapse7" class="panel-collapse collapse">
                    <div class="panel-body">Cleaning charges for short term are define by your host and can differ from property to property</div>
                  </div>
                </div>
            
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse8">Do I need to be vaccinated to book a property? </a>
                    </h4>
                  </div>
                  <div id="collapse8" class="panel-collapse collapse">
                    <div class="panel-body">It is not mandatory but if your host has requested vaccination documents then you need to upload them on PodsLiving </div>
                  </div>
                </div>
            
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse9">Is there a min. stay to book a property? </a>
                    </h4>
                  </div>
                  <div id="collapse9" class="panel-collapse collapse">
                    <div class="panel-body">There is no min. stay for short-term renal you can book anything from 1 day to 31 days </div>
                  </div>
                </div>
            
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse10">I Where I can find an invoice for my booking details </a>
                    </h4>
                  </div>
                  <div id="collapse10" class="panel-collapse collapse">
                    <div class="panel-body"> Your invoice is available at your dashboard on PodsLiving</div>
                  </div>
                </div>
            
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse11">Can I book a shred room? </a>
                    </h4>
                  </div>
                  <div id="collapse11" class="panel-collapse collapse">
                    <div class="panel-body"> Yes, you can book a shared room at PodsLiving.ca </div>
                  </div>
                </div>
            
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse12">Can I book entire place? </a>
                    </h4>
                  </div>
                  <div id="collapse12" class="panel-collapse collapse">
                    <div class="panel-body"> Yes, you can book entire place at PodsLiving.ca  </div>
                  </div>
                </div>
            
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse13">Can I book private room? </a>
                    </h4>
                  </div>
                  <div id="collapse13" class="panel-collapse collapse">
                    <div class="panel-body"> Yes, you can book a private room at PodsLiving.ca   </div>
                  </div>
                </div>
            
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse14">Can I book room based on my preferences? </a>
                    </h4>
                  </div>
                  <div id="collapse14" class="panel-collapse collapse">
                    <div class="panel-body"> Yes, you can book property based on your preference using PodsLiving preference based search    </div>
                  </div>
                </div>
            
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse15"> How do I msg the host</a>
                    </h4>
                  </div>
                  <div id="collapse15" class="panel-collapse collapse">
                    <div class="panel-body">  You can msg host through your dashboard on PodsLiving </div>
                  </div>
                </div>
            
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapse16">How do I find the property that I booked? </a>
                    </h4>
                  </div>
                  <div id="collapse16" class="panel-collapse collapse">
                    <div class="panel-body">You can find your booked property at your dashboard on PodsLiving</div>
                  </div>
                </div>
            
              </div>
          </div>
          <div id="Property_Listing" class="tab-pane fade">
            <div class="panel-group" id="accordion-pl">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse1-pl">Do I need to pay to list my property?</a>
                    </h4>
                  </div>
                  <div id="collapse1-pl" class="panel-collapse collapse in">
                    <div class="panel-body"> Listing property is free at PodsLiving</div>
                  </div>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse2-pl"> Can I list a property for long term leasing on PodsLiving?</a>
                    </h4>
                  </div>
                  <div id="collapse2-pl" class="panel-collapse collapse">
                    <div class="panel-body">Yes, you can list your property on PodsLiving for long term leasing</div>
                  </div>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse3-pl"> How do I get my STR number?</a>
                    </h4>
                  </div>
                  <div id="collapse3-pl" class="panel-collapse collapse">
                    <div class="panel-body"> You can apply for a short tern rental number at PodsLiving.ca/str</div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse3-pl1">Can I list a property with multiple owners? </a>
                    </h4>
                  </div>
                  <div id="collapse3-pl1" class="panel-collapse collapse">
                    <div class="panel-body"> Yes, you can list property with multiple owners </div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse4-pl">What are the requirements to lease a property?</a>
                    </h4>
                  </div>
                  <div id="collapse4-pl" class="panel-collapse collapse">
                    <div class="panel-body">Please visit PodsLiving.ca/property-lease-requirements </div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse5-pl">Do I need to follow and local regulation before I list my property?</a>
                    </h4>
                  </div>
                  <div id="collapse5-pl" class="panel-collapse collapse">
                    <div class="panel-body">Yes, please refer to your local municipal hosting/listing laws </div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse6-pl">Can I do feature listing </a>
                    </h4>
                  </div>
                  <div id="collapse6-pl" class="panel-collapse collapse">
                    <div class="panel-body">You can do feature listing at PodsLiving</div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse7-pl">How do I make my listing as feature listing?</a>
                    </h4>
                  </div>
                  <div id="collapse7-pl" class="panel-collapse collapse">
                    <div class="panel-body"> PodsLiving offers variety of feature listing options to their users visit at PodsLiving.ca/featured-listings</div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse8-pl">What are the benefits of feature listing?</a>
                    </h4>
                  </div>
                  <div id="collapse8-pl" class="panel-collapse collapse">
                    <div class="panel-body"> You can get your property booked faster </div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse9-pl">Can I connect to a host before I book? </a>
                    </h4>
                  </div>
                  <div id="collapse9-pl" class="panel-collapse collapse">
                    <div class="panel-body">It depends if your host has provided the contact details you can contact them</div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse10-pl">What are notarization charges </a>
                    </h4>
                  </div>
                  <div id="collapse10-pl" class="panel-collapse collapse">
                    <div class="panel-body">Notarization charges are defined by host and may differ by location and property</div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse11-pl">Who pays the notarization charges? </a>
                    </h4>
                  </div>
                  <div id="collapse11-pl" class="panel-collapse collapse">
                    <div class="panel-body">Depends on host how he has defined the notarization charges while listing </div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse12-pl"> Can I define my own rental agreement? </a>
                    </h4>
                  </div>
                  <div id="collapse12-pl" class="panel-collapse collapse">
                    <div class="panel-body">Yes, you can define your own terms and condition in tenancy agreement</div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse13-pl">What are the charges to host my property?</a>
                    </h4>
                  </div>
                  <div id="collapse13-pl" class="panel-collapse collapse">
                    <div class="panel-body">Hosting is free at PodsLiving.ca </div>
                  </div>
                </div>
                 <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-pl" href="#collapse14-pl">Why do I need to verify myself?</a>
                    </h4>
                  </div>
                  <div id="collapse14-pl" class="panel-collapse collapse">
                    <div class="panel-body">To prevent the fraudulent activities PodsLiving verify all their hosts/guests </div>
                  </div>
                </div>
            
              </div> 
          </div>
          <div id="Partners_Services" class="tab-pane fade">
            <div class="panel-group" id="accordion-ps">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-ps" href="#collapse1-ps">How can I partner with PodsLiving?</a>
                    </h4>
                  </div>
                  <div id="collapse1-ps" class="panel-collapse collapse in">
                    <div class="panel-body"> You can register at PodsLiving.ca/partners/register</div>
                  </div>
                </div>
                </div>
           </div>
          <div id="Value_Added_Services" class="tab-pane fade">
            <div class="panel-group" id="accordion-vas">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-vas" href="#collapse1-vas">Can I but tenant insurance with PodsLiving</a>
                    </h4>
                  </div>
                  <div id="collapse1-vas" class="panel-collapse collapse in">
                    <div class="panel-body"> Visit PodsLiving.ca/partner/insurance to get in touch with PodsLiving Insurance partners</div>
                  </div>
                </div>
                
            
                <div class="panel-group" id="accordion-vas">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" href="#collapse2-vas">Can I request cleaning services with PodsLiving for my properties? </a>
                    </h4>
                  </div>
                  <div id="collapse2-vas" class="panel-collapse collapse">
                    <div class="panel-body"> You can request cleaning services at via PodsLiving.ca/services/cleaning</div>
                  </div>
                </div>
                </div>
                <div class="panel-group" id="accordion-vas">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-vas" href="#collapse3-vas">What is your property hosting/maintenance services?</a>
                    </h4>
                  </div>
                  <div id="collapse3-vas" class="panel-collapse collapse ">
                    <div class="panel-body">PodsLiving offers complete hosting services visit us at PodsLiving.ca/hosting </div>
                  </div>
                </div>
                </div>
                <div class="panel-group" id="accordion-vas">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h4 class="panel-title">
                      <a data-toggle="collapse" data-parent="#accordion-vas" href="#collapse4-vas">Can I get a Guarantor? </a>
                    </h4>
                  </div>
                  <div id="collapse4-vas" class="panel-collapse collapse">
                    <div class="panel-body">Yes you can avail PodsLiving guarantor services at PodsLiving.ca/need-gurantor </div>
                  </div>
                </div>
                </div>
          
              </div> 
           </div>
          <div id="Pricing_Management" class="tab-pane fade">
            <h3>Pricing Management</h3>
           </div>
          <div id="Common_Questions" class="tab-pane fade">
            <div class="row">
                <div class="col-md-12">
                    <div class="panel-group" id="accordion-common">
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse1-common">What does PodsLiving do</a>
                            </h4>
                          </div>
                          <div id="collapse1-common" class="panel-collapse collapse in">
                            <div class="panel-body">PodsLiving provide top notch hosting and booking services</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse2-common"> How do I register? </a>
                            </h4>
                          </div>
                          <div id="collapse2-common" class="panel-collapse collapse">
                            <div class="panel-body"> You can register at PodsLiving.ca/register</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse3-common"> How to reset password</a>
                            </h4>
                          </div>
                          <div id="collapse3-common" class="panel-collapse collapse">
                            <div class="panel-body">Go to PodsLiving.ca/login</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse4-common">Forgot my password </a>
                            </h4>
                          </div>
                          <div id="collapse4-common" class="panel-collapse collapse">
                            <div class="panel-body">Click on PodsLiving.ca/reset/password</div>
                          </div>
                        </div>
                    
                         <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse5-common">How do I unlock my account? </a>
                            </h4>
                          </div>
                          <div id="collapse5-common" class="panel-collapse collapse">
                            <div class="panel-body">Please drop an email to admin@podsliving.com</div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse6-common">How can I register as Host and Guest both?</a>
                            </h4>
                          </div>
                          <div id="collapse6-common" class="panel-collapse collapse">
                            <div class="panel-body"> You can register as host and guest both at PodsLiving.ca/register</div>
                          </div>
                        </div>
                    
                         <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse7-common">How do I contact PodsLiving?</a>
                            </h4>
                          </div>
                          <div id="collapse7-common" class="panel-collapse collapse">
                            <div class="panel-body">You can contact PodsLiving at hello@podsliving.com</div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse8-common">Does PodsLiving licensed in Canada</a>
                            </h4>
                          </div>
                          <div id="collapse8-common" class="panel-collapse collapse">
                            <div class="panel-body">PodsLiving is a federally registered company in Canada and a licensed short term rental operator  </div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse9-common">What are your cancellation policies? </a>
                            </h4>
                          </div>
                          <div id="collapse9-common" class="panel-collapse collapse">
                            <div class="panel-body"> Please visit PodsLiving.ca/policies/cancellation</div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse10-common">What are your refund options?</a>
                            </h4>
                          </div>
                          <div id="collapse10-common" class="panel-collapse collapse">
                            <div class="panel-body"> Please visit PodsLiving.ca/policies/refund</div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse11-common">What are my payment options?</a>
                            </h4>
                          </div>
                          <div id="collapse11-common" class="panel-collapse collapse">
                            <div class="panel-body">   PodsLiving accept all major credit cards </div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse12-common">Can I use multiple cards to make my booking payment?</a>
                            </h4>
                          </div>
                          <div id="collapse12-common" class="panel-collapse collapse">
                            <div class="panel-body">  PodsLiving offers split payments, you can use multiple cards to make payment </div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse13-common">Where I can look for PodsLiving career opportunities</a>
                            </h4>
                          </div>
                          <div id="collapse13-common" class="panel-collapse collapse">
                            <div class="panel-body"> Visit PodsLiving.ca/career</div>
                          </div>
                        </div>
                    
                         <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse14-common"> What is PodsLiving digital leasing flow</a>
                            </h4>
                          </div>
                          <div id="collapse14-common" class="panel-collapse collapse">
                            <div class="panel-body"> Landlord and Tenant as PodsLiving can digitally create and sign the tenancy agreement </div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse15-common">  Are there any hidden service charges?</a>
                            </h4>
                          </div>
                          <div id="collapse15-common" class="panel-collapse collapse">
                            <div class="panel-body"> PodsLiving service charges are transparent to their users visit at PodsLiving.ca/charges </div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse16-common">Do I need to pay any taxes? </a>
                            </h4>
                          </div>
                          <div id="collapse16-common" class="panel-collapse collapse">
                            <div class="panel-body">You need to pay applicable government taxes based on your property location </div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse17-common">Can I pay in other currencies? </a>
                            </h4>
                          </div>
                          <div id="collapse17-common" class="panel-collapse collapse">
                            <div class="panel-body">You can pay in any currency but PodsLiving only accepts in $</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse18-common">What is short term/long term hosting </a>
                            </h4>
                          </div>
                          <div id="collapse18-common" class="panel-collapse collapse">
                            <div class="panel-body">At PodsLiving you can do short-term hosting of your property and long term lease of your property</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse19-common">What is my property showing options?</a>
                            </h4>
                          </div>
                          <div id="collapse19-common" class="panel-collapse collapse">
                            <div class="panel-body"> PodsLiving encourage you to show your own property and you can also designate/appoint anyone to show your property on your behalf</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse20-common">What are your terms of use?</a>
                            </h4>
                          </div>
                          <div id="collapse20-common" class="panel-collapse collapse">
                            <div class="panel-body">Visit at PodsLiving.ca/policies </div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse21-common"> What are company policies </a>
                            </h4>
                          </div>
                          <div id="collapse21-common" class="panel-collapse collapse">
                            <div class="panel-body"> Visit at PodsLiving.ca/policies  </div>
                          </div>
                        </div>
                         <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse22-common">What type of property I can list?</a>
                            </h4>
                          </div>
                          <div id="collapse22-common" class="panel-collapse collapse">
                            <div class="panel-body"> You can list any type of property at PodsLiving for more details visit us at PodsLiving.ca/list-property </div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse23-common">How do I use a promotion code?</a>
                            </h4>
                          </div>
                          <div id="collapse23-common" class="panel-collapse collapse">
                            <div class="panel-body"> not answer sheet </div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse24-common"> Can I refer my friend? </a>
                            </h4>
                          </div>
                          <div id="collapse24-common" class="panel-collapse collapse">
                            <div class="panel-body"> You can refer a friend using PodsLiving refer and earn program  </div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse25-common">Can I update my profile?  </a>
                            </h4>
                          </div>
                          <div id="collapse25-common" class="panel-collapse collapse">
                            <div class="panel-body"> You can always update your profile from your dashboard section on PodsLiving</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse26-common">How do I remove property from my fav. </a>
                            </h4>
                          </div>
                          <div id="collapse26-common" class="panel-collapse collapse">
                            <div class="panel-body"> You can remove your property from your dashboard on PodsLiving</div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse27-common">Can I provide review to host/guest?</a>
                            </h4>
                          </div>
                          <div id="collapse27-common" class="panel-collapse collapse">
                            <div class="panel-body">You can provide your reviews on PodsLiving </div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse28-common">I didn't find property that I am looking for </a>
                            </h4>
                          </div>
                          <div id="collapse28-common" class="panel-collapse collapse">
                            <div class="panel-body">  You can fill the form at PodsLiving.ca </div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse29-common">How do I contact PodsLiving?</a>
                            </h4>
                          </div>
                          <div id="collapse29-common" class="panel-collapse collapse">
                            <div class="panel-body">You can drop an email at PodsLiving.ca/contat-us </div>
                          </div>
                        </div>
                    
                         <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse30-common">Do you have a mobile app?</a>
                            </h4>
                          </div>
                          <div id="collapse30-common" class="panel-collapse collapse">
                            <div class="panel-body">Yes, please download from google app store </div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse31-common">What is instant booking</a>
                            </h4>
                          </div>
                          <div id="collapse31-common" class="panel-collapse collapse">
                            <div class="panel-body"> You can book property instantly and there is no wait period for the property to book</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse32-common">Can I change cover photo for my property?</a>
                            </h4>
                          </div>
                          <div id="collapse32-common" class="panel-collapse collapse">
                            <div class="panel-body"> You can always edit your listing through your dashboard at podslving</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse33-common">Can I use the same email/phone to create two different profiles?</a>
                            </h4>
                          </div>
                          <div id="collapse33-common" class="panel-collapse collapse">
                            <div class="panel-body">You need different email/phone number to create another profile </div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse34-common">Can I electronically sign the lease agreement?</a>
                            </h4>
                          </div>
                          <div id="collapse34-common" class="panel-collapse collapse">
                            <div class="panel-body">At PodsLiving you do not need to do wet signature you can use PodsLiving digital signature</div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse35-common">Can I add extra terms and conditions to lease agreement?</a>
                            </h4>
                          </div>
                          <div id="collapse35-common" class="panel-collapse collapse">
                            <div class="panel-body">You can always amend terms and conditions of least agreement before you sign it</div>
                          </div>
                        </div>
                         <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse36-common">Can I use my social media account to register at PodsLiving?</a>
                            </h4>
                          </div>
                          <div id="collapse36-common" class="panel-collapse collapse">
                            <div class="panel-body"> You can use any social media account to sign on PodsLiving</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse37-common">Can I close or delete my account?</a>
                            </h4>
                          </div>
                          <div id="collapse37-common" class="panel-collapse collapse">
                            <div class="panel-body"> You can request PodsLiving to close or delete your account at PodsLiving</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse38-common">What is an OTP</a>
                            </h4>
                          </div>
                          <div id="collapse38-common" class="panel-collapse collapse">
                            <div class="panel-body"> OTP is One Time Password </div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse39-common">Can I register as host and guest both?</a>
                            </h4>
                          </div>
                          <div id="collapse39-common" class="panel-collapse collapse">
                            <div class="panel-body">You can register as both at PodsLiving </div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse40-common">How can I combine my profile? </a>
                            </h4>
                          </div>
                          <div id="collapse40-common" class="panel-collapse collapse">
                            <div class="panel-body">You need to register at PodsLiving as host and guest </div>
                          </div>
                        </div>
                    
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse41-common">Can I book a property if I register as Host? </a>
                            </h4>
                          </div>
                          <div id="collapse41-common" class="panel-collapse collapse">
                            <div class="panel-body">Yes, if you have registered as host and guest both at PodsLiving </div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse42-common">Can I search property by specific amenities/facilities? </a>
                            </h4>
                          </div>
                          <div id="collapse42-common" class="panel-collapse collapse">
                            <div class="panel-body">Yes, you can search property by amenities/facilities on PodsLiving</div>
                          </div>
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <h4 class="panel-title">
                              <a data-toggle="collapse" data-parent="#accordion-common" href="#collapse43-common">How much are PodsLiving Marchant charges  </a>
                            </h4>
                          </div>
                          <div id="collapse43-common" class="panel-collapse collapse">
                            <div class="panel-body">PodsLiving charge standard Marchant charges </div>
                          </div>
                        </div>
                    
                    
                    
                      </div> 
                </div>
    
                
            </div>
          </div>
        </div>
      </div>
      




</section>