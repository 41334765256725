<section class="address-slide-view gap-50 position-relative">
    <img src="../assets/images/career.jpg" class="img-fluid" />

    <div class="container  banner-title ">

        <div class="row">
            <div class="col-md-12">
                <h1 class="banner-over-text">Careers at PodsLiving</h1>
            </div>


        </div>
    </div>

</section>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>Current Openings</h2>

                <p>

                </p>
            </div>
            <div class="col-md-12 mt-5">
                <div id="accordion">
                    <div class="card">
                        <div class="card-header">
                            <a class="card-link" data-toggle="collapse" href="#collapse1">
                                <h3>Full Stack Developer</h3>
                                <div class="job-tags">
                                    <span><img src="../assets/images/location-m.svg" /> Full Time </span>&nbsp;
                                    <span><img src="../assets/images/calendar.svg" /> Toronto, Canada</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" class="podsliving-button" data-toggle="collapse" href="#collapse1">View job Details</a>

                        </div>
                        <div id="collapse1" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <p>We are looking for a highly skilled computer programmer who is comfortable with both front and back end programming. Full stack developers are responsible for developing and designing front end web architecture, ensuring
                                    the responsiveness of applications, and working alongside graphic designers for web design features, among other duties.
                                    <p>

                                        <p>Full stack developers will be required to see out a project from conception to final product, requiring good organizational skills and attention to detail.</p>
                                        <div class="">


                                            <div class="details">
                                                <h4>Full Stack Developer Responsibilities:</h4>
                                                <ul>
                                                    <li>Developing front end website architecture.</li>
                                                    <li> Designing user interactions on web pages.</li>
                                                    <li> Developing back-end website applications.</li>
                                                    <li> Creating servers and databases for functionality.</li>
                                                    <li> Ensuring cross-platform optimization for mobile phones.</li>
                                                    <li> Ensuring responsiveness of applications.</li>
                                                    <li> Working alongside graphic designers for web design features.</li>
                                                    <li> Seeing through a project from conception to finished product.</li>
                                                    <li> Designing and developing APIs.</li>
                                                    <li> Meeting both technical and consumer needs.</li>
                                                    <li> Staying abreast of developments in web applications and programming languages.
                                                    </li>
                                                </ul>
                                                <h4> Full Stack Developer Requirements:</h4>

                                                <ul>

                                                    <li> Degree in computer science.</li>
                                                    <li> Strong organizational and project management skills.</li>
                                                    <li>Proficiency with fundamental front-end languages such as HTML, CSS, and JavaScript.
                                                    </li>
                                                    <li> Familiarity with JavaScript frameworks such as Angular JS, React, and Amber.
                                                    </li>
                                                    <li> Proficiency with server-side languages such as Python, Ruby, Java, PHP, and .Net.
                                                    </li>
                                                    <li> Familiarity with database technology such as MySQL, Oracle, and MongoDB.
                                                    </li>
                                                    <li>Excellent verbal communication skills.</li>
                                                    <li> Good problem-solving skills.</li>
                                                    <li> Attention to detail.</li>
                                                </ul>
                                            </div>
                                            <a href="javaScript:void(0);" class="podsliving-button  m-width-auto" data-toggle="modal" data-target="#exampleModalCenter" data-value="Full Stack Developer" (click)="applyNowOpenModel($event, 'Full Stack Developer');">Apply Now</a>
                                        </div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <a class="card-link" data-toggle="collapse" href="#collapse2">
                                <h3>Angualr Developer</h3>
                                <div class="job-tags">
                                    <span><img src="../assets/images/location-m.svg" /> Full Time </span>&nbsp;
                                    <span><img src="../assets/images/calendar.svg" /> Toronto, Canada</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" class="podsliving-button" data-toggle="collapse" href="#collapse2">View job Details</a>

                        </div>
                        <div id="collapse2" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <p>We are looking to hire an experienced angularJS developer to join our dynamic team. you will be responsible for creating a top-level coding-base using Angular best practices. Your role will require you to implement an exciting
                                    and streamlined user experience in the form of a Java-based desktop and mobile web-app.
                                </p>
                                <p> To ensure success as an angularJS developer, you should have extensive knowledge of theoretical software engineering, be proficient in JavaScript, HTML, and CSS, and have excellent project management skills. Ultimately,
                                    a top-class angularJS developer can design and build a streamlined application to company specifications that perfectly meet the needs of the user.
                                </p>
                                <div class="">
                                    <div class="details">
                                        <h4>AngularJS Developer Responsibilities:</h4>

                                        <ul>
                                            <li>Designing and developing user interfaces using angularJS best practices.
                                            </li>
                                            <li>Adapting interface for modern internet applications using the latest front-end technologies.
                                            </li>
                                            <li>Writing JavaScript, CSS, and HTML.</li>
                                            <li>Developing product analysis tasks.</li>
                                            <li>Making complex technical and design decisions for AngularJS projects.
                                            </li>
                                            <li>Developing application codes and unit tests in AngularJS, Java Technologies, and Rest Web Services.</li>
                                            <li>Conducting performance tests.</li>
                                            <li>Consulting with the design team.</li>
                                            <li>Ensuring high performance of applications and providing support.</li>
                                        </ul>

                                        <h4>AngularJS Developer Requirements:</h4>
                                        <ul>
                                            <li>Previous work experience as an angularJS developer.</li>
                                            <li>Proficient in CSS, HTML, and writing cross-browser compatible code.</li>
                                            <li>Experience using JavaScript building tools like Gulp or Grunt.</li>
                                            <li>Knowledge of JavaScript MV-VM/MVC frameworks including AngluarJS.</li>
                                            <li>Excellent project management skills.</li>
                                            <li>Proven experience implementing front-end and back-end JavaScript applications.
                                            </li>
                                            <li>Excellent communication skills.</li>
                                            <li>Critical thinker and good problem-solver.</li>
                                        </ul>
                                    </div>
                                    <a href="javaScript:void(0);" class="podsliving-button  m-width-auto" data-toggle="modal" data-target="#exampleModalCenter" data-value="Angualr Developer" (click)="applyNowOpenModel($event, 'Angualr Developer');">Apply Now</a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="card">
                        <div class="card-header">
                            <a class="card-link" data-toggle="collapse" href="#collapse3">
                                <h3>senior Creative digital content writer</h3>
                                <div class="job-tags">
                                    <span><img src="../assets/images/location-m.svg" /> Full Time </span>&nbsp;
                                    <span><img src="../assets/images/calendar.svg" /> Toronto, Canada</span>
                                </div>
                            </a>
                            <a href="javascript:void(0);" class="podsliving-button" data-toggle="collapse" href="#collapse3">View job Details</a>

                        </div>
                        <div id="collapse3" class="collapse" data-parent="#accordion">
                            <div class="card-body">

                                <div class="">
                                    <div class="details">
                                        <h4>senior Creative digital content writer (online & mobile app)</h4>

                                        <ul>
                                            <li>Content Writer -2-5 Years</li>
                                        </ul>
                                        <p>
                                            Looking for a senior Creative digital content writer (online & mobile app) with prior experience in writing and creating content for #digital a real estate rental marketplace #realestate #writing. Preferably ex-Airbnb, ex-Verbo, or any other real estate-related
                                            sites. #contentwriter #creative If anyone is interested please share your profile and respective work.
                                        </p>
                                    </div>
                                    <a href="javaScript:void(0);" class="podsliving-button  m-width-auto mt-3" data-toggle="modal" data-target="#exampleModalCenter" data-value="Angualr Developer" (click)="applyNowOpenModel($event, 'senior Creative digital content writer');">Apply Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- <div class="col-md-12 mt-5">
                <hr>
            </div>
            <div class="col-md-12 mt-5">
                <div class="row mb-5">
                    <div class="col-md-12">
                        <h2>Apply Here</h2>

                        <p>

                        </p>
                    </div>
                </div>
                <form>
                    <div class="row justify-content-center">
                        <div class="col-md-4 form-group">
                            <label>Full Name</label>
                            <input type="text" class="form-control">

                        </div>
                        <div class="col-md-4 form-group">
                            <label>Email Id</label>
                            <input type="email" class="form-control">
                        </div>
                        <div class="col-md-4 form-group">
                            <label>Mobile Number</label>
                            <input type="tel" class="form-control">

                        </div>
                        <div class="col-md-4 form-group">
                            <label>Location (City)</label>
                            <input type="text" class="form-control">
                        </div>
                        <div class="col-md-4 form-group">
                            <label>LinkedIn Profile URL</label>
                            <input type="text" class="form-control">

                        </div>
                        <div class="col-md-4 form-group">
                            <label>Upload Your Resume</label>
                            <input type="file" class="form-control-file">
                        </div>
                        <div class="col-md-3 form-group">
                            <button type="submit" class="podsliving-button w-100 m-width-auto ">Submit</button>
                        </div>
                    </div>
                </form>

            </div> -->
        </div>
    </div>
</section>

<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{jobTitle}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="careerForm">
                    <div class="row justify-content-center">
                        <div class="col-md-4 form-group">
                            <label>Full Name</label>
                            <input type="text" formControlName="name" class="form-control" placeholder="Fullname" [ngClass]="{
                            'is-invalid':
                            applyNowFormControl.name.errors &&
                            (applyNowFormControl.name.dirty ||
                            applyNowFormControl.name.touched)
                        }">
                            <div *ngIf="applyNowFormControl.name.errors" class="error-msg">
                                {{ applyNowFormControl.name.errors.incorrect }}
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label>Email Id</label>
                            <input type="email" class="form-control" formControlName="email" placeholder="Email" [ngClass]="{
                            'is-invalid':
                            applyNowFormControl.email.errors &&
                            (applyNowFormControl.email.dirty ||
                            applyNowFormControl.email.touched)
                        }">
                            <div *ngIf="applyNowFormControl.email.errors" class="error-msg">
                                {{ applyNowFormControl.email.errors.incorrect }}
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label>Mobile Number</label>
                            <input type="tel" class="form-control" formControlName="mobile" placeholder="" [ngClass]="{
                            'is-invalid':
                            applyNowFormControl.mobile.errors &&
                              (applyNowFormControl.mobile.dirty ||
                              applyNowFormControl.mobile.touched)
                          }">
                            <div *ngIf="applyNowFormControl.mobile.errors" class="error-msg">
                                {{ applyNowFormControl.mobile.errors.incorrect }}
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label>Location (City)</label>
                            <input type="text" class="form-control" formControlName="location" placeholder="" [ngClass]="{
                            'is-invalid':
                            applyNowFormControl.location.errors &&
                              (applyNowFormControl.location.dirty ||
                              applyNowFormControl.location.touched)
                          }">
                            <div *ngIf="applyNowFormControl.location.errors" class="error-msg">
                                {{ applyNowFormControl.location.errors.incorrect }}
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label>LinkedIn Profile URL</label>
                            <input type="text" class="form-control" formControlName="linkedin_url" placeholder="" [ngClass]="{
                            'is-invalid':
                            applyNowFormControl.linkedin_url.errors &&
                              (applyNowFormControl.linkedin_url.dirty ||
                              applyNowFormControl.linkedin_url.touched)
                          }">
                            <div *ngIf="applyNowFormControl.linkedin_url.errors" class="error-msg">
                                {{ applyNowFormControl.linkedin_url.errors.incorrect }}
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <label>Upload Your Resume</label>
                            <input type="file" accept=".pdf, .doc, .docx" formControlName="resume" class="form-control-file" id="chooseFile_1" (change)="selectFile($event)">
                            <div *ngIf="applyNowFormControl.resume.errors" class="error-msg">
                                {{ applyNowFormControl.resume.errors.incorrect }}
                            </div>
                        </div>

                        <div class="col-md-12 form-group">
                            <div class="text-success p-2" *ngIf="careerFormSuccess">
                                <span>{{ successMsg }}</span>
                                <i class="close-icon fa fa-times ml-3 cursor-pointer" (click)="careerFormSuccess = !careerFormSuccess"></i>
                            </div>
                            <div class="red-text p-2" *ngIf="careerFormFailed">
                                <span>{{ failedMsg }}</span>
                                <i class="close-icon fa fa-times ml-3 cursor-pointer" (click)="careerFormFailed = !careerFormFailed"></i>
                            </div>
                        </div>

                        <div class="col-md-3 form-group">
                            <button class="podsliving-button w-100 m-width-auto" (click)="sendCareerForm()" [ngClass]="{'button-disable': !careerForm.valid}">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>