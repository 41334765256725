<section class="polices-view mt-70">
    <div class="container p-0">
        <div class="row">
            <div class="col-md-12 mt-70">
                <div class="text-center 404" *ngIf="validationType=='success'">
                    <h3 style="font-size: 9pt; color: green">Verification succesful. Please login to continue.</h3>
                </div>
                <div class="text-center 404" *ngIf="validationType=='failed'">
                    <h3 style="font-size: 9pt; color: red">Verification failed.</h3>
                </div>
            </div>
        </div>
    </div>
    
    
</section>