<section class="banner header-gap gap-50 position-relative">
    <img src="../assets/images/cleaning-banner.jpg" class="w-100 img-fluid" alt="" />
</section>

<div class="container">
    <section class="explore-carousel-wrapp  featured-property">
        <h1 class="title-v mb-4">About PodsLiving</h1>
        <p>PodsLiving was founded in April 2021 by two former colleagues with a combined industry experience of over
            forty years. The platform meets all living and travel related demands for travellers, professionals, and
            students whilst providing with an affordable, comfortable, and modern living environment.</p>
        <p>PodsLiving offers it all! Whether you're a homeowner seeking to monetize your property or a student searching
            for an affordable place to stay, we've got you covered. With the true intent of making your business trips
            and personal travel adventures more enjoyable and restful. </p>
        <p>Our platform offers excellent host and guest connectivity options. Host can use the platform to take
            bookings, accept payments, and interact with trusted guests, while providing guests the capabilities of
            booking their favorite vacation, make payments via all different avenues including cryptocurrency, receive
            communications and other booking related administration digitally. PodsLiving is the most ideal and
            convenient platform for host to entertain culturally diverse guests. </p>
        <p>Students looking for a nearby housing in close proximity to world-renowned universities and institutes might
            benefit greatly from the short-term rental services provided by us. Students may reach out to us digitally
            or explore the site to identify, compare, and book the finest accommodations for their requirements and
            preferences.</p>
        <p>PodsLiving has more to offer! Vacationers can reserve rental houses with spectacular views to enhance their
            holiday experience. To create a secure living environment and sustain quality of living, we verify all our
            hosts.</p>
        <p>Now this beautiful website, on which you are reading this, was carefully designed and memorably launched in
            May 2021. PodsLiving aims to deliver an extraordinary experience in managing seasonal pricing along with
            customized and flexible refund and cancellation policies. </p>
        <p>Enjoy the experience by booking your next vacation or listing to your property! </p>

    </section>

    <div class="row align-items-center m-0 bg1 mt-5">
        <div class="col-md-6">
            <div class="side-text">
                <h2>Top-Notch Hosting And Guest Connection Services</h2>
                <p>Our platform provides top-notch hosting and guest connection services. The platform allows renters to
                    receive reservations, accept payments, manage their properties, and connect with verified customers.
                </p>
            </div>
        </div>
        <div class="col-md-6 pr-0 pl-0">
            <img src="../assets/images/ss11.jpg" class="img-fluid w-100" alt="" />
        </div>
    </div>
    <div class="row align-items-center m-0 bg2 mt-5">
        <div class="col-md-6  pr-0 pl-0">
            <img src="../assets/images/ss22.jpg" class="img-fluid w-100" alt="" />
        </div>
        <div class="col-md-6">
            <div class="side-text">
                <h2>End to End Fully Digitized Services</h2>
                <p>The functions of booking, payment, communication, guest, and booking management make it the most
                    suitable solution for those users who want to offer their properties for both short-term and
                    long-term rental.</p>
            </div>
        </div>

    </div>
    <div class="row align-items-center bg1 m-0 mt-5">
        <div class="col-md-6">
            <div class="side-text">
                <h2>We help students find the best place to stay near universities & College campuses.</h2>
                <p>Students can reach us via our contact us page or browser the site to discover, compare, and book the
                    best accommodation based on their needs and other personal preferences. All featured properties on
                    the website are verified by our partners
                    to ensure a safe living experience.</p>
            </div>
        </div>
        <div class="col-md-6  pr-0 pl-0">
            <img src="../assets/images/ss33.jpg" class="img-fluid w-100" alt="" />
        </div>
    </div>

    <!-- <div class="row mt-5 pt-4 justify-content-between">
        <div class="col-md-12 mb-4"><h1>About PodsLiving</h1></div>
        <div class="col-md-4 w1">
            <div class="team-member">
                <img src="../assets/images/adiran.png" class="img-fluid" alt=""/>
                
                <div class="middle">
                    <h3 class="text-center mb-4">Adrian Gupta</h3>
                    
                    <div class="text">Adrian is an accomplished Business and IT professional with strategic thinking, transformation results and process improvements. He has a unique ability to see the full spectrum of solutions. He is always to the point, enthusiast, early riser and ever ready to deliver. His service orientation is sure to bring you bonus benefits along with seamless lifestyle. He has specialized digital expertise with successful side hustle in real estate and public services.</div>
                  </div>
            </div>
        </div>
        <div class="col-md-4 w1">
            <div class="team-member">
                <img src="../assets/images/mahen.png" class="img-fluid" alt=""/>
               
                <div class="middle">
                    <h3 class="text-center mb-4">Mahen Rawal</h3>
                    
                    <div class="text">Over 20 years of experience within service industry with a focus on improving customer experience and engagement. A leader with a strategic mindset and strong execution skills to drive technology and digital transformations across multiple sectors. Collaborative and resilient leader known for identifying strategic opportunities, pushing the boundaries to drive change and execute with excellence. </div>
                  </div>
            </div>
        </div>
        <div class="col-md-4 w1">
            <div class="team-member">
                <img src="../assets/images/venice.png" class="img-fluid" alt=""/>
              
                <div class="middle">
                    <h3 class="text-center mb-4">Venice Kelly</h3>
                    
                    <div class="text">Venice Kelly comes with around 15 years of experience helping educate students about the campus life, history, traditions, and achievements of the college through supporting the Student Life & Leadership departments, conducting campus tours, supporting high school recruitment events, and supporting Presidential and Foundation functions.</div>
                  </div>
            </div>
        </div>
    </div> -->
</div>