<div class="sub-page-wrapp recent-booking-wrapp mt-70" *ngIf="property">
    <div class="container">
        <div class="row">
            <div class="col-md-12 mt-5">
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-between page-title-block">
                        <div>
                            <h2 class="mb-2">{{ property.pro_title }}</h2>
                            <div class="gray-text mb-3">
                                <span class="mr-2" *ngIf="property.rating"><img src="./assets/images/star.svg" alt=""
                                        class="mr-1" />{{
                                    property.rating
                                    }}
                                </span>
                                <a class="blue-text text-decoration-none" *ngIf="">{{ property.review_count
                                    }}Reviews</a>
                                <span class="gray-text">
                                    {{ property.city ? property.city : "" }}
                                    {{ property.state ? ", " + property.state : "" }}
                                    {{
                                    property.country_region
                                    ? ", " + property.country_region
                                    : ""
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="d-flex">

                            <a href="javascript:void(0);" (click)="heartIcon = !heartIcon;Add_Remove_Fav();" class="rounded-icons">
                                <img *ngIf="heartIcon" src="./assets/images/heart-fill.svg" alt="" />
                                <img *ngIf="!heartIcon" src="./assets/images/favorites-black.svg" alt="" />
              
                              </a>
                            <a href="javascript:void(0);" class="rounded-icons">
                                <img src="./assets/images/share.svg" alt="" />
                                <share-buttons [theme]="'modern-dark'" [include]="[
                    'facebook',
                    'twitter',
                    'linkedin',
                    'email',
                    'whatsapp'
                  ]" [show]="5" [showIcon]="true" [showText]="true">
                                </share-buttons>
                            </a>
                            <!-- <a href="javascript:void(0);" class="rounded-icons">
                                <img src="./assets/images/favorite-24px.svg" alt="" title="Add to WishList" /></a> -->
                        </div>
                    </div>
                    <div class="ml-3 mb-3" *ngIf="property.featured_property!=null"  class="featuredClass">Featured

                    </div>
                    <div class="col-md-12 galler-wrapp">
                        <div class="galler-big-wrapp">
                            <img src="{{
                  property.coverImageData ? property.coverImageData.image : ''
                }}" onerror="this.src='/assets/images/placeholder.png';" alt="" data-toggle="modal"
                                data-target="#gallery_slider_longterm" />
                            <div class="over-text other" *ngIf="
                  property.coverImageData &&
                  property.coverImageData.image_caption
                ">
                                {{
                                property.coverImageData
                                ? property.coverImageData.image_caption
                                : ""
                                }}
                            </div>
                        </div>
                        <div class="galler-sm-wrapp">
                            <ng-container *ngFor="let images of property.property_longterm_image; let i = index">
                                <ng-container *ngIf="images.is_primary == 0">
                                    <div *ngIf="i == 0" class="galler-sm-block">
                                        <ng-container
                                            *ngFor=" let images of property.property_longterm_image; let j = index">
                                            <div *ngIf="j < 2 && images.file_type != ''">
                                                <img src="{{ imageURL + images.image }}"
                                                    onerror="this.src='/assets/images/placeholder.png';"
                                                    *ngIf="images.file_type == 'image'" alt="" data-toggle="modal"
                                                    data-target="#gallery_slider_longterm" />
                                                <video class="w-100" width="278" height="196"
                                                    *ngIf="images.file_type == 'video'" type="video/mp4" controls>
                                                    <source src="{{ imageURL + images.image }}" />
                                                </video>
                                                <div class="over-text other"
                                                    *ngIf="images.image_caption && images.file_type == 'image'">
                                                    {{ images.image_caption }}
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="i == 2" class="galler-sm-block">
                                        <ng-container
                                            *ngFor=" let images of property.property_longterm_image; let j = index">
                                            <div *ngIf="j == 2 && images.file_type != ''">
                                                <img class="jj" src="{{ imageURL + images.image }}"
                                                    onerror="this.src='/assets/images/placeholder.png';"
                                                    *ngIf="images.file_type == 'image'" alt="" data-toggle="modal"
                                                    data-target="#gallery_slider_longterm" />
                                                <video class="w-100" width="278" height="196"
                                                    *ngIf="images.file_type == 'video'" type="video/mp4" controls>
                                                    <source src="{{ imageURL + images.image }}" />
                                                </video>
                                                <div class="over-text other"
                                                    *ngIf="images.image_caption && images.file_type == 'image'">
                                                    {{ images.image_caption }}
                                                </div>
                                            </div>
                                            <div *ngIf="j == 3 && images.file_type != ''">
                                                <img src="{{ imageURL + images.image }}"
                                                    *ngIf="images.file_type == 'image'" alt="" data-toggle="modal"
                                                    data-target="#gallery_slider_longterm" />
                                                <video class="w-100 jj" width="278" height="196"
                                                    *ngIf="images.file_type == 'video'" type="video/mp4" controls>
                                                    <source src="{{ imageURL + images.image }}" />
                                                </video>
                                                <div class="over-text other"
                                                    *ngIf="images.image_caption && images.file_type == 'image'">
                                                    {{ images.image_caption }}
                                                </div>
                                                <a href="javascript:void(0);" data-toggle="modal"
                                                    data-target="#gallery_slider_longterm" class="galler-overlay-block"
                                                    *ngIf="property.property_longterm_image?.length > 4">+{{
                                                    property.property_longterm_image?.length - 4 }} Photos &
                                                    Videos</a>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tabs-scroll-wrapp">
        <div class="container">
            <div class="row">
                <div class="col-md-12 mx-auto">
                    <ul>
                        <li [ngClass]="{ active: activeTab == 'about' }" (click)="scrollToView(about)"> <a>About</a>
                        </li>
                        <li [ngClass]="{ active: activeTab == 'amen_fac' }" (click)="scrollToView(amen_fac)">
                            <a>Facility & Amenity</a>
                        </li>
                        <!-- <li [ngClass]="{ active: activeTab == 'policies' }" (click)="scrollToView(policies)">
                            <a>House Rules</a>
                        </li> -->
                        <li [ngClass]="{ active: activeTab == 'location' }" (click)="scrollToView(location)">
                            <a>Location</a>
                        </li>
                        <!-- <li [ngClass]="{ active: activeTab == 'additionalDetails' }"
                            (click)="scrollToView(additionalDetails)"> <a>Additional Details</a>
                        </li> -->
                        <li [ngClass]="{ active: activeTab == 'host' }" (click)="scrollToView(host)"> <a>Host</a></li>
                        <li [ngClass]="{'active':activeTab == 'review_block'}" (click)="scrollToView(review_block)"><a>Reviews</a>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12 mx-auto">
                <div class="row mt-4">
                    <div class="col-md-8">
                        <h3 class="propertyTitle">
                            {{
                            property.property_category &&
                            property.property_category.cat_name
                            ? property.property_category.cat_name + " in "
                            : ""
                            }}
                            {{ property.pro_title ? property.pro_title : "" }}
                            {{ property.city ? " in " + property.city : "" }}
                        </h3>
                        <div class="mt-1" id="about" #about>

                            {{
                            property.property_longterm_attribute &&
                            property.property_longterm_attribute.no_of_occupants
                            ? property.property_longterm_attribute.no_of_occupants + " Occupant(s) •"
                            : ""
                            }}

                            {{
                            property.property_longterm_attribute &&
                            property.property_longterm_attribute.bedrooms
                            ? property.property_longterm_attribute.bedrooms + " Bedroom(s) •"
                            : ""
                            }}

                            {{
                            property.property_longterm_attribute &&
                            property.property_longterm_attribute.baths
                            ? property.property_longterm_attribute.baths + " Bathroom(s) •"
                            : ""
                            }}

                            {{
                            property.property_longterm_attribute &&
                            property.property_longterm_attribute.parkings &&
                            property.property_longterm_attribute.parkings > 0
                            ? property.property_longterm_attribute.parkings +
                            " Parking(s) available •"
                            : "No parking available •"
                            }}


                            {{
                            property.property_longterm_attribute &&
                            property.property_longterm_attribute.students_allowed
                            ? " Students Allowed •"
                            : ""
                            }}
                            {{
                            property.property_longterm_attribute &&
                            property.property_longterm_attribute.smoking
                            ? " Smoking Allowed •"
                            : ""
                            }}
                            {{
                            property.property_longterm_attribute &&
                            property.property_longterm_attribute.shared_bathroom
                            ? " Bathroom Shared Allowed •"
                            : ""
                            }}
                            {{
                            property.property_longterm_attribute &&
                            property.property_longterm_attribute.shared_parking
                            ? " Parking Shared Allowed •"
                            : ""
                            }}

                            {{
                            property.property_longterm_attribute &&
                            property.property_longterm_attribute.pets
                            ? " Pets Allowed •"
                            : ""
                            }}

                            {{
                            property.property_longterm_attribute &&
                            property.property_longterm_attribute.utility_included &&
                            property.property_longterm_attribute.utility_items
                            ? " Utility available : " + property.property_longterm_attribute.utility_items : ""
                            }}
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-12">
                                <p>
                                    {{ property.property_description }}
                                </p>
                            </div>
                        </div>

                        <div id="amen_fac" id="amen_fac" #amen_fac>
                            <div class="row mt-1" *ngIf="property.amenities?.length > 0">
                                <div class="col-md-12">
                                    <h3 class="mb-3">Facility & Amenity</h3>
                                    <div class="row">
                                        <div
                                            class="col-md-12 type-of-space-block-wrapp d-flex align-items-center rec-booking-icon-block">
                                            <ng-container *ngFor="let amenties of property.amenities">
                                                <label class="type-spc-block">
                                                    <input type="radio" name="layout" />
                                                    <div class="type-of-space-block d-flex align-items-center type-of-property image"
                                                        title="{{ amenties.service_name }}">
                                                        <span><img src="{{ amenties.service_image }}"
                                                                alt="{{ amenties.service_name }}" /></span>
                                                        <span>{{ amenties.service_name }}</span>
                                                    </div>
                                                </label>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 
                        <div class="row mt-1 label-with-tick-wrapp" id="policies" #policies>
                            <div class="col-md-12" *ngIf="property.property_rules?.length > 0">
                                <h3 class="mb-3">House Rules</h3>
                                <div class="row">
                                    <div class="col-md-12 d-flex justify-content-between">
                                        <ul class="list">
                                            <ng-container *ngFor="
                                                let rule of property.property_rules;
                                                let i = index
                                                ">
                                                <li class="color0" *ngIf="rule.rule">
                                                    {{ rule.rule }}
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="row mt-1" id="location" #location>
                            <div class="col-md-12">
                                <h3>Location</h3>
                                <div class="my-3">
                                    <!-- {{ property.address1 ? property.address1 : " " }} {{ property.address2 ? property.address2 : " " }} -->
                                    {{ property.city ? property.city : ""
                                    }}{{ property.state ? ", " + property.state : ""
                                    }}{{
                                    property.country_region
                                    ? ", " + property.country_region
                                    : ""
                                    }}
                                </div>
                                <google-map height="450" width="100%" [zoom]="14" [center]="center">
                                    <map-marker #marker="mapMarker" [position]="center"
                                        (mouseOver)="openInfoWindow(marker, infoWindow)"></map-marker>
                                    <map-info-window #infoWindow="mapInfoWindow">
                                        <strong>
                                            <p>{{ property.pro_title }}</p>
                                        </strong>
                                        <strong>
                                            <p>{{ property.google_address }}</p>
                                        </strong>
                                        <strong>
                                            <p>{{ property.city }}</p>
                                        </strong>
                                        <strong>
                                            <p>{{ property.state }}</p>
                                        </strong>
                                    </map-info-window>
                                </google-map>
                            </div>
                        </div>
                        <div class="row mt-4" *ngIf="nearByPlaces">
                            <div class="col-md-12">
                                <h3 class="mb-3">What’s Nearby</h3>
                                <div class="nearby-wrapp d-flex justify-content-between">
                                    <ng-container *ngFor="let nearByPlaces of nearByPlaces; let i = index">
                                        <a class="nearby-block d-flex justify-content-between" *ngIf="i < 10">
                                            <div class="d-flex">
                                                <img src="{{ nearByPlaces.icon }}" alt="" />
                                                <div class="nearByTitle">{{ nearByPlaces.name }}</div>
                                            </div>
                                            <div class="nearByValue">
                                                {{ getKmFromNearByPlace(nearByPlaces) }}Km
                                            </div>
                                        </a>
                                    </ng-container>
                                </div>
                            </div>
                        </div>



                        <div class="row mt-1" id="host" #host>
                            <div class="col-md-12" *ngIf="property.property_host">
                                <h3 class="mb-3">About Your Host</h3>
                                <div class="about-host-wrapp d-flex justify-content-between mb-3">
                                    <div class="about-host-info-block d-flex">
                                        <div class="about-host-image-block">
                                            <img src="{{ property.hostProfilePic }}" alt="" />
                                        </div>
                                        <div>
                                            <div class="text-bold">
                                                {{
                                                property.property_host &&
                                                property.property_host.firstname
                                                ? property.property_host.firstname +
                                                " " +
                                                property.property_host.lastname
                                                : ""
                                                }}
                                            </div>
                                            <div class="star-icon-block d-flex mt-2"></div>
                                        </div>
                                    </div>
                                    <div *ngIf="
                                        property.property_host &&
                                        property.property_host.kyc_status &&
                                        property.property_host &&
                                        property.property_host.kyc_status == 1
                                        " class="about-host-verified-block">
                                        <img src="./assets/images/verified-user.svg" alt="" class="mr-2" />
                                        <span>Verified Host</span>
                                    </div>
                                </div>
                                <p class="mb-3">
                                    {{
                                    property.property_host && property.property_host.user_bio
                                    ? property.property_host.user_bio
                                    : ""
                                    }}
                                </p>

                            </div>
                        </div>
                        <div class="row mt-5" id="review_block" #review_block>
                            <div class="row mt-1 pl-3">
                              <h3 class="mb-3 col-md-12">Review
                                <span class="gray-text">({{property.total_revew?property.total_revew:0 }})</span>
                              </h3>
                              <div class="col-md-12 mb-3" *ngFor="let item of reviewList" >
                                <div class="about-host-wrapp d-flex justify-content-between mb-3">
                                  <div class="about-host-info-block d-flex">
                                    <div class="about-host-image-block">
                                      <!-- <img src="{{ item.guest_profile_picture }}" alt="" /> -->
                                      <img src="{{ item.guest_profile_picture ? this.imageURL + item.guest_profile_picture : './assets/images/testimonail.png'}}" alt="" />

                                    </div>
                                    <div>
                                      <div class="text-bold">
                                        {{
                                        item.guest_lastname &&
                                        item.guest_firstname
                                        ? item.guest_firstname +
                                        " " +
                                        item.guest_lastname
                                        : ""
                                        }}
                                      </div>
                                      <div class="star-icon-block d-flex mt-2">
                                        <span  class="mr-2"><img  src="./assets/images/star.svg" alt="" class="mr-1">{{item.reviews_rating ? item.reviews_rating : 0}} </span>
                                      </div>
                                    </div>
                                  </div>
                                 
                                </div>
                                <p class="mb-3">
                                  {{
                                    item?.review_description 
                                  ? item?.review_description
                                  : ""
                                  }}
                                </p>
                               </div>
                            </div>
                          </div>

  <!-- ///////////////////////////////////// -->
  <!-- <div class="col-md-12 mt-4 p-0">
    <div class="d-flex justify-content-between">
      <h3 class="mb-2">
        Reviews <span class="gray-text">({{property.reviews_arr?.length }})</span>
      </h3>
    </div>
   
    <div class="col-md-12 p-0">
      <div id="review_form_wrapper">
        <div id="review_form">
          <div id="respond" class="comment-respond">
            <form id="form-review" class="comment-form has-validation-callback">
              <div class="comment-form-rating d-flex mb-2">
                <label for="rating"><strong>Give your rating&nbsp;<span
                      class="required">*</span></strong></label>
                <div class="rating-p">
                  <div class="star-icon-block d-flex justify-content-end" style="cursor: pointer;">
                    <span *ngFor="let star of stars; let i = index" (click)="rate(i + 1)">
                      <ng-container *ngIf="star; else noStar">⭐</ng-container>
                      <ng-template #noStar>★</ng-template>
                    </span>
                    <span class="gray-text">({{guestRatting}})</span>

                  </div>
                </div>
              </div>
              <div class="comment-form-comment ">
                <label for="text" class="mb-1"><strong>Add your review&nbsp;<span class="required">*</span></strong></label>
                <textarea class="pl-2" id="text" name="text" cols="45" rows="8"  [(ngModel)]="reviewDetails" data-validation="required"
                  placeholder="Type Something"></textarea>
              </div>
              <p class="form-submit">
                <button class="podsliving-button  m-width-auto" (click)="rattingSubmit()"> Submit </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- ////////////////////////////////// -->

                    </div>
                    <div class="col-md-4">
                        <div class="booking-flow-right-block border-block">
                            <form [formGroup]="bookingForm" (ngSubmit)="booking()" *ngIf="!bookingFormResponse">

                                <div class="form-group">
                                    <div class="d-flex justify-content-between mt-4">
                                        <span class="">Monthly Rent</span>
                                        <strong> {{( property.property_longterm_leasing_details &&
                                            property.property_longterm_leasing_details.monthly_rent
                                            ? property.property_longterm_leasing_details.monthly_rent
                                            : 0 ) | currency }} </strong>
                                    </div>
                                </div>
                                <div class="form-group mb-70">
                                    <label class="mb-2 text-bold">Lease Duration</label>
                                    <select nice-select placeholder="Select lease duration"
                                        formControlName="lease_duration" class="w-100 form-control" [ngClass]="{
                                        'is-invalid':
                                        bookingFormControl.lease_duration.errors &&
                                        (bookingFormControl.lease_duration.dirty ||
                                        bookingFormControl.lease_duration.touched)
                                    }">
                                        <option value="" disabled>Select lease duration</option>
                                        <option *ngFor="let element of leaseDurations" value="{{ element.value }}">
                                            {{element.text }}</option>
                                    </select>
                                    <div *ngIf="bookingFormControl.lease_duration.errors" class="error-msg"> {{
                                        bookingFormControl.lease_duration.errors.incorrect }} </div>
                                </div>


                                <div class="form-group">
                                    <label class="mb-2 text-bold">Approximate Lease Start Date</label>
                                    <div class="position-relative">
                                        <input style="cursor: pointer;" placeholder="Select Lease Start Date"
                                            class="w-100 form-control" formControlName="lease_start_date" bsDatepicker
                                            [bsConfig]="{  
                                            dateInputFormat: 'MMM DD, YYYY',
                                            showWeekNumbers: false ,
                                            containerClass: 'theme-blue',
                                            minDate: minimumDate
                                        }" [ngClass]="{
                                            'is-invalid':
                                            bookingFormControl.lease_start_date.errors &&
                                            (bookingFormControl.lease_start_date.dirty ||
                                            bookingFormControl.lease_start_date.touched)
                                        }">
                                        <img src="./assets/images/date.svg" alt="" class="input-icon right-0" />
                                    </div>
                                    <div *ngIf="bookingFormControl.lease_start_date.errors" class="error-msg"> {{
                                        bookingFormControl.lease_start_date.errors.incorrect }} </div>
                                </div>

                                <div class="form-group pt-20">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div>
                                            <div><strong>Is Student</strong></div>
                                        </div>
                                        <label class="switch">
                                            <input type="checkbox" formControlName="is_student">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>


                                <div class="row justify-content-between  pt-20">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <div class="f-w-bold mb-2">No. of Occupants</div>
                                            <div id="field1">
                                                <button type="button" id="sub" class="sub form-control"
                                                    (click)="decreaseOccupants('no_of_occupants')">-</button>
                                                <input type="number" formControlName="no_of_occupants">
                                                <button type="button" id="add" class="add form-control"
                                                    (click)="increaseOccupants('no_of_occupants')">+</button>
                                                <div *ngIf="bookingFormControl.no_of_occupants.errors"
                                                    class="error-msg"> {{
                                                    bookingFormControl.no_of_occupants.errors.incorrect }} </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5" *ngIf="!bookingFormControl.is_student.value">
                                        <div class="form-group">
                                            <div class="f-w-bold mb-2">No. of Earning Member</div>
                                            <div id="field1">
                                                <button type="button" id="sub" class="sub"
                                                    (click)="decreaseEarningMember('no_of_earning_members')">-</button>
                                                <input type="number" formControlName="no_of_earning_members">
                                                <button type="button" id="add" class="add"
                                                    (click)="increaseEarningMember('no_of_earning_members')">+</button>
                                                <div *ngIf="bookingFormControl.no_of_earning_members.errors"
                                                    class="error-msg"> {{
                                                    bookingFormControl.no_of_earning_members.errors.incorrect }}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="!bookingFormControl.is_student.value">

                                    <ng-container *ngFor="let item of earning_member_details; let i=index">

                                        <div class="form-group overflow-hidden pt-3">
                                            <label class="mb-2 text-bold">Employment Status - Member {{i+1}}</label>
                                            <select nice-select placeholder="Select Employment Status"
                                                [(ngModel)]="item.employment_status"
                                                [ngModelOptions]="{ standalone: true }" class="w-100 form-control"
                                                [ngClass]="{ 'is-invalid': item.employment_status.errors && (item.employment_status.dirty || item.employment_status.touched)}">
                                                <option value="" disabled>Select Employment Status</option>
                                                <option *ngFor="let element of earning_member_status"
                                                    value="{{ element }}">
                                                    {{element }}</option>
                                            </select>
                                        </div>
                                        <div class="form-group overflow-hidden mt-70"
                                            *ngIf="item.employment_status == 'Other'">
                                            <input type="text" class="form-control"
                                                [(ngModel)]="item.other_status_text"
                                                [ngModelOptions]="{ standalone: true }"
                                                placeholder="Enter other employment status*">
                                        </div>
                                        <div class="form-group overflow-hidden">
                                            <label class="mb-2 mt-2  text-bold">Gross Annual Income - Member {{i+1}}</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">$</span>
                                                </div>
                                            <input type="text" class="form-control text-right"
                                                placeholder="$0,000.00" mask="separator.2" thousandSeparator=","
                                                [(ngModel)]="item.grass_annual_income"
                                                [ngModelOptions]="{ standalone: true }"
                                                [ngClass]="{ 'is-invalid': item.grass_annual_income.errors && (item.grass_annual_income.dirty || item.grass_annual_income.touched)}"></div>
                                        </div>

                                    </ng-container>

                                    <div class="d-flex justify-content-between">
                                        <label class="mb-2 text-bold">Total Annual Household Income</label>
                                        <span class="text-right" >{{totalIncomeMember | currency}}</span>
                                    </div>
                                </ng-container>
                                <button type="submit" class="podsliving-button w-100 mt-3">Show Interest</button>
                            </form>

                            <ng-container *ngIf="bookingFormResponse">
                                <p class="alert alert-success"> {{bookingFormResponse.message}}</p>
                                <a class="podsliving-button w-100" href="javascript:void(0);" (click)="bookignRequestPage()">My
                                    Dashboard</a>
                            </ng-container>

                            <ng-container *ngIf="bookingFormError">
                                <p class="alert alert-danger mt-4"> {{bookingFormError.message}}</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



</div>










<!-- Image Slide show model  -->
<div class="modal fade" id="gallery_slider_longterm" tabindex="-1" role="dialog" aria-labelledby="cancellation_policy"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="modal-block pl-0 pr-0">
                    <div class="modal-title-block d-flex justify-content-between side-gap">
                        <h2 class="magrin0">Photos & Videos</h2>
                        <a href="javascript:void(0);" data-dismiss="modal" class="exit-button">Exit</a>
                    </div>
                    <div class="outer">
                        <owl-carousel #big id="big" [options]="SlideOptions"
                            *ngIf=" property && property.all_image && property.all_image.length">
                            <div class="item" *ngFor="let images of property.all_image; let i = index">
                                <img src="{{ imageURL + images.image }}" *ngIf="images.file_type == 'image'"
                                    class="img-fluid" />
                                <video class="w-100" width="278" height="460" *ngIf="images.file_type == 'video'"
                                    type="video/mp4" controls>
                                    <source src="{{ imageURL + images.image }}" />
                                </video>
                                <p *ngIf="images.image_caption">{{ images.image_caption }}</p>
                            </div>
                        </owl-carousel>
                        <owl-carousel #thumbs id="thumbs" [options]="Thumbptions"
                            *ngIf="property && property.all_image && property.all_image.length">
                            <div class="item" *ngFor="let images of property.all_image; let i = index">
                                <img (click)="updateBigCarousel(i)" src="{{ imageURL + images.image }}"
                                    *ngIf="images.file_type == 'image'" class="img-fluid" />
                                <video (click)="updateBigCarousel(i)" class="w-100" width="278" height="220"
                                    class="img-fluid" *ngIf="images.file_type == 'video'" type="video/mp4" controls>
                                    <source src="{{ imageURL + images.image }}" />
                                </video>
                                <p *ngIf="images.image_caption">{{ images.image_caption }}</p>
                            </div>
                        </owl-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>