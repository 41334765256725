import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-works-partner',
  templateUrl: './how-it-works-partner.component.html',
  styleUrls: ['./how-it-works-partner.component.css']
})
export class HowItWorksPartnerComponent implements OnInit {
  title = 'How It Works Partner';
  constructor() { }

  ngOnInit(): void {
  }

}
