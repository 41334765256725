<section class="address-slide-view gap-50 position-relative">
    <img src="../assets/images/works-banner.jpg" class="img-fluid" />

    <div class="container  banner-title ">

        <div class="row">
            <div class="col-md-12">
                <h1 class="banner-over-text">{{title}}</h1>
            </div>


        </div>
    </div>

</section>
<section class="gap-50 pt-0">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="title-v mb-4 font-w-normal">How It Works?</h1>
                <p>PodsLiving will provide you following hosting guidance for listing and maintenance services.</p>
            </div>
        </div>
        <div class="row gap-50 align-items-center">

            <div class="col-md-3">
                <div class="border-box">
                    <img src="../assets/images/icon2.jpg" class="img-fluid" alt="" />
                    <h4>Your work, Your Style</h4>
                    <p>Choose Services as per your preference</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="border-box">
                    <img src="../assets/images/icon3.jpg" class="img-fluid" alt="" />
                    <h4>Plan your work as per your schedule</h4>
                    <p>Plan your work in your convinience</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="border-box">
                    <img src="../assets/images/icon1.jpg" class="img-fluid" alt="" />
                    <h4>Get best service charges</h4>
                    <p>Get the worthy purse for your services</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="border-box">
                    <img src="../assets/images/icon4.jpg" class="img-fluid" alt="" />
                    <h4>Tagline</h4>
                    <p>Choose Services as per your preference</p>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12 text-center">
                <h1 class="title-v mb-4 font-w-normal">Simple Process</h1>
                <p>You only pay us minimal fee on the total service charge for completed jobs through PodsLiving. NO HIDDEN CHARGES!</p>
            </div>
        </div>
        <div class="row gap-50 justify-content-between">

            <div class="col-md-4 w-30">
                <div class="border-box-grey">
                    <span class="number">1</span>
                    <h4>Register & Submit your details</h4>
                    <p>Any Government issued ID, Service Credentials & Reference</p>
                </div>
            </div>
            <div class="col-md-4 w-30">
                <div class="border-box-grey">
                    <span class="number">2</span>
                    <h4>Activate</h4>
                    <p>We perform back-check on your service profile and will activate you.</p>
                </div>
            </div>
            <div class="col-md-4 w-30">
                <div class="border-box-grey">
                    <span class="number">3</span>
                    <h4>Get Business</h4>
                    <p>We route the leads generated from clients directly to you.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-3 "><a href="#" class="podsliving-button w-100 ">Register</a></div>
        </div>

    </div>

</section>