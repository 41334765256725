import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-center',
  templateUrl: './information-center.component.html',
  styleUrls: ['./information-center.component.css']
})
export class InformationCenterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
