export const ApiEndpoints = {
  LOGIN: 'auth/login',
  GOOGLE_LOGIN: 'auth/login/google/callback',
  FACEBOOK_LOGIN: 'auth/login/facebook/callback',
  APPLE_LOGIN: 'auth/login/apple/callback',
  REGISTER: 'auth/register',
  LOGIN_OTP: 'auth/validate-login-otp',
  REGISTER_OTP: 'auth/validate-registration-otp',
  RESEND_OTP_LOGIN: 'auth/auth-resend-otp/login',
  RESEND_OTP_REGISTER: 'auth/auth-resend-otp/register',
  RESEND_OTP_RESET_PASSWORD: 'auth/auth-resend-otp/passwordreset',
  FORGOT_PASSWORD: 'auth/request-reset-password',
  RESET_PASSWORD: 'auth/reset-password',
  VERIFY_EMAIL: 'auth/resent-email-verification',

  //profile
  PROFILE_PIC_UPDATE: 'profile/save-profile-picture',
  GET_PROFILE_DETAIL: 'profile/my-profile',
  SAVE_PROFILE_INFO: 'profile/save-general-info',
  SAVE_CONTACT_INFO: 'profile/save-contact-info',
  SAVE_STUDENT_INFO: 'profile/save-student-info',
  SAVE_DOCUMENT_INFO: 'profile/save-document-info',
  CHANGE_PASSWORD: 'profile/change-password',
  DELETE_PROFILE: 'profile/profile-status-management',

  UPDATE_EMAIL: 'profile/update-email',
  UPDATE_MOBILE: 'profile/update-phone',
  UPDATE_MOBILE_OTP_RESEND:'auth/auth-resend-otp/phoneupdate',
  SAVE_PREFERENCE_STEP1:'preference/step1',
  SAVE_PREFERENCE_STEP2:'preference/step2',
  SAVE_PREFERENCE_STEP3:'preference/step3',

  UPDATE_MOBILE_OTP: 'profile/validate-phoneupdate-otp',
  POST_HST_NUMBER:'profile/update-hst',
  UPDATE_KYC: 'kyc/user/verify',
  GET_KYC_STATUS: 'kyc/user/verify-status',
  PAYOUT:'profile/host-payout-detail',
  KYC_APPLY_REWARD_POINTS:  'kyc/apply-reward-points',
  KYC_APPLY_WALLET:  'kyc/apply-wallet-ballance',
  KYC_SUBMIT_WALLET_PAYMENT:'kyc/confirm-wallet-booking',
  KYC_GET_PAYMENT_INTENT:'kyc/stripe-payment-intent',
  KYC_SUBMIT_PAYMENT:'kyc/confirm-stripe-book',
  KYC_SUBMIT_PAYPAL:'kyc/confirm-btree-booking',


  // General
  SUBSCRIPTION: 'general/subscribe-email',
  WHAT_LOOKING_FOR: 'general/what-looking-for',
  CONTACT_US: 'general/save-contact-us',
  APPLY_FOR_WEBINAR:'general/apply-for-webinar',
  SUBSCRIBE_HOST_EMAIL:'general/subscribe-host-email',
  FAQ: 'general/faq',
  

  //Property
  CREATE_PROPERTY: 'property/create-property',
  GET_PROPERTY_TYPES: 'general/category-list',
  PROPERTY_STEP1: 'property/property-detail-step-1',
  PROPERTY_STEP2: 'property/property-detail-step-2',
  PROPERTY_STEP3: 'property/property-detail-step-3',
  PROPERTY_STEP4: 'property/property-detail-step-4',
  PROPERTY_STEP5: 'property/property-detail-step-5',
  PROPERTY_STEP6: 'property/property-detail-step-6',
  PROPERTY_STEP7: 'property/publish',
  PROPERTY_IMAGE_DELETE: 'property/property-image-delete',
  CANCELLATION_POLICY_LIST: 'general/cancel-policy-list',
  SAVE_CANCELLATION_POLICY: 'property/property-cancellation-policy',
  SAVE_AVAILABILITY: 'property/property-not-available-dates',
  


  PROPERTY_EDIT_STEP0: 'property/property-step0-get-data',
  PROPERTY_EDIT_STEP1: 'property/property-step1-get-data',
  PROPERTY_EDIT_STEP2: 'property/property-step2-get-data',
  PROPERTY_EDIT_STEP3: 'property/property-step3-get-data',
  PROPERTY_EDIT_STEP4: 'property/property-step4-get-data',
  PROPERTY_EDIT_STEP5: 'property/property-step5-get-data',
  PROPERTY_EDIT_STEP6: 'property/property-step6-get-data',

  EDIT_CANCELLATION_POLICY: 'property/property-cancellation-policy-get-data',

  SEASON_LIST: 'general/seasonal-list',
  PROPERTY_STEP_IMAGE_UPLOAD: 'property/property-upload-file',
  PREFERENCES_IMAGE_UPLOAD: 'preference/upload-attachment',

  PROPERTY_IMAGE_LIST: 'auth/property-img-list',
  PROPERTY_STEP_IMAGE_UPDATE: 'property/property-image-update',
  ATTRIBUTES_LIST: 'general/attributes-list',
  AMENITIES: 'general/amenity-facility-list',

  GET_PROPERTY_LIST: 'general/property-list',
  
  GET_COUPON_LIST: 'profile/get-coupons',
  CHANGE_COUPON_STATUS: 'profile/change-coupon-status',

  CREATE_COUPON: 'profile/create-coupon',
  UPDATE_COUPON: 'profile/update-coupon',

  SHORT_TERM_REVIEW_LIST: 'reviews/shortterm-reviews',
  SHORT_TERM_WISH_LIST: 'property/property-wishlist',

  GET_PROPERTY_LISTS: 'general/property-lists',
  DELETE_SHORT_TERM_REVIEW: 'reviews/delete-review-request',
  REMOVE_FROM_WISHLIST: 'property/property-delete-wishlist',

  GET_ATTRIBUTE_BY_CAT: 'general/attributes-list-By-categories',
  PROPERTY_DETAIL: 'general/property-detail',
  SERVICES_MASTER_LIST: 'general/amenity-facility-list',
  SERVICES_ADD_REVIEW: 'property/property-addreview',
  ADD_TO_FAVOURITE: 'property/property-add-wishlist',

  ADD_LONGTERM_REVIEW: 'property/longterm-property-addreview',
  GET_LONGTERM_REVIEW_LIST: 'general/longterm-property-review-list',
  GET_SHORTTERM_REVIEW_LIST: 'general/property-review-list',

  SEND_MSG_TO_PROP_HOST: 'auth/sendto-pro-msg',

  //Long Term Property
  longTermProperty:{
    CREATE_PROPERTY: 'property/longterm-create-property',
    PROPERTY_STEP1: 'property/longterm-property-detail-step-1',
    PROPERTY_STEP2: 'property/longterm-property-detail-step-2',
    PROPERTY_STEP3: 'property/longterm-property-detail-step-3',
    PROPERTY_PUBLISH: 'property/longterm-publish',

    PROPERTY_STEP_IMAGE_UPLOAD: 'property/longterm-property-upload-file',
    PROPERTY_STEP_IMAGE_UPDATE: 'property/longterm-property-image-update',
    PROPERTY_STEP_LEASING_DETAILS: 'property/longterm-property-leasing-details',
    GET_PROPERTY_DETAIL: 'property/longterm-property-detail-byid/',
    PROPERTY_IMAGE_DELETE: 'property/longterm-property-image-delete',
    ATTRIBUTES_LIST: 'general/longterm-attributes-list',
    GET_PROPERTY_LIST: 'general/longterm-property-list',
    LONG_TERM_REVIEW_LIST: 'reviews/longterm-reviews',

    GET_PROPERTY_LISTS: 'general/longterm-property-lists',
    PROPERTY_DETAIL: 'general/longterm-property-detail',

    BOOKING: 'booking/longterm-request-booking',

    GET_BOOKING_REQUESTS: 'booking/longterm-my-booking-requests',
    GET_BOOKING_REQUEST_DETAIL: 'booking/longterm-booking-request-detail',

    REJECT: 'booking/longterm-cancel-booking-request',
    SCHEDULE: 'booking/schedule-showing',
    SCHEDULE_SHOW: 'booking/showing-date-selection',
    RESCHEDULE: 'booking/reschedule-showing',

    CONFIRMATION: 'booking/property-viewing-confirmation',
    COMPLETION: 'booking/request-completion-steps',
    FILE_UPLOAD: 'booking/upload-verification-files',
    VERIFICATION: 'booking/document-verification',
    ADD_EARNING_MEMBER: 'booking/add-earning-member',
    REMOVE_EARNING_MEMBER: 'booking/remove-earning-member',
    VERIFY_LOGGEDIN_USER: 'booking/verify-loggedin-user',
    VERIFICATION_AMOUNT: 'booking/verification-amount-calculation',
    VERIFICATION_AMOUNT_PHONE: 'booking/verification-amount-calculation-by-phone',

    //payment
    SUBMIT_PAYPAL:'booking/longterm-confirm-btree-booking',

    APPLY_WALLET:'booking/longterm-apply-wallet-ballance',
    SUBMIT_WALLET_PAYMENT:'booking/longterm-confirm-wallet-booking',
  
    GET_PAYMENT_INTENT:'booking/longterm-stripe-payment-intent',
    SUBMIT_PAYMENT:'booking/longterm-confirm-stripe-book',

    APPLY_REWARD_POINTS:  'booking/longterm-apply-reward-points',

    //rental agreement
    GET_LEASETERM_DETAILS:'booking/leaseterm-lease-details',
    GET_SKIP_LIST:'booking/leaseterm-get-skip-screen',
    SKIP:'booking/leaseterm-add-skip-screen',
    SUBMISSION:'booking/leaseterm-lease-submission',
    DOC_SUBMISSION:'booking/leaseterm-docusign',
    leaseterm_1:'booking/leaseterm-screen_1',
    leaseterm_2:'booking/leaseterm-screen_2',
    leaseterm_3:'booking/leaseterm-screen_3',
    leaseterm_4:'booking/leaseterm-screen_4',
    leaseterm_5:'booking/leaseterm-screen_5',
    leaseterm_6:'booking/leaseterm-screen_6',
    leaseterm_7:'booking/leaseterm-screen_7',
    leaseterm_8:'booking/leaseterm-screen_8',
    leaseterm_9:'booking/leaseterm-screen_9',
    leaseterm_10:'booking/leaseterm-screen_10',
    leaseterm_11:'booking/leaseterm-screen_11',
    leaseterm_12:'booking/leaseterm-screen_12',
    leaseterm_13:'booking/leaseterm-screen_13',
    leaseterm_14:'booking/leaseterm-screen_14',
    leaseterm_15:'booking/leaseterm-screen_15',
    leaseterm_16:'booking/leaseterm-screen_16',
    leaseterm_17:'booking/leaseterm-screen_17',
    leaseterm_18:'booking/leaseterm-screen_18',
    leaseterm_19:'booking/leaseterm-screen_19',
    leaseterm_20:'booking/leaseterm-screen_20',
    leaseterm_21:'booking/leaseterm-screen_21',
    leaseterm_22:'booking/leaseterm-screen_22',
    leaseterm_23:'booking/leaseterm-screen_23',
    leaseterm_24:'booking/leaseterm-screen_24',
    leaseterm_25:'booking/leaseterm-screen_25',
    leaseterm_26:'booking/leaseterm-screen_26',
    leaseterm_27:'booking/leaseterm-screen_27',
    leaseterm_28:'booking/leaseterm-screen_28',
    leaseterm_29:'booking/leaseterm-screen_29',
    leaseterm_30:'booking/leaseterm-screen_30',
    leaseterm_31:'booking/leaseterm-screen_31',
    leaseterm_32:'booking/leaseterm-screen_32',
    leaseterm_33:'booking/leaseterm-screen_33',

    FEEDBACK: 'booking/longterm-feedback',

    GET_BOOKING_LIST: 'booking/longterm-my-bookings',
    LINK_SHARE:'booking/meeting-link-for-property-showing',
    Document_Check_By_Host:'booking/document-check-by-host',
    GUARANTOR_VERIFICATION: 'booking/save-guarantor-info',
    RE_VERIFICATION: 'booking/document-verification-update',

  },


  //Featured Property
  Featured_Packages : 'general/feature-listing-packages',
  Featured_Create_Short_Term: 'feature-listing/create-vacational-feature-listing',
  Featured_Create_Long_Term: 'feature-listing/create-longterm-feature-listing',
  Featured_Short_Term : 'feature-listing/shortterm-feature-listings',
  Featured_Long_Term : 'feature-listing/longterm-feature-listings',
  Featured_APPLY_WALLET:'feature-listing/apply-wallet-ballance',
  Featured_SUBMIT_WALLET_PAYMENT:'feature-listing/confirm-wallet-booking',
  Featured_GET_PAYMENT_INTENT:'feature-listing/stripe-payment-intent',
  Featured_SUBMIT_PAYMENT:'feature-listing/confirm-stripe-book',
  Featured_SUBMIT_PAYPAL:'feature-listing/confirm-btree-booking',
  Featured_APPLY_REWARD_POINTS:  'feature-listing/apply-reward-points',

  //Booking
  CHECK_BOOKING: 'general/checkBooking',

  //Change User Type to both
  CHANGE_USER_TYPE:'profile/update-role',

  LOGOUT: 'auth/logout',

  // PROPERTY API
  GET_PROPERTY_DETAIL: 'property/property-detail-byid/',

  //TOP DESTINATION BY CATEGORY
  GET_TOP_DESTINATION_BY_CATEGORY:'general/top-list-by-category',

  //career formm request
  CAREER_FORM: 'general/career-form',
  DASHBOARD_BOOKING_COUNT: 'booking/dashboard-booking-count',
  DASHBOARD_COUNT: 'dashboard/dashboard-count',

  //get Booking list
  GET_BOOKING_LIST: 'booking/my-bookings',
  //get Booking Requests
  GET_BOOKING_REQUESTS: 'booking/my-booking-requests',
  CHANGE_STATUS_REQUEST: 'booking/booking-request-action',
  GET_BOOKING_DETAIL: 'booking/booking-detail',
  GET_BOOKING_REQUEST_DETAIL: 'booking/booking-request-detail',
  GET_BOOKING_INVOICE: 'booking/invoice',
  CANCEL_BOOKING: 'booking/cancel-booking',
  //Get Invoices
  GET_INVOICES: 'booking/my-invoices',
  //Get Invoices
  GET_REFERALS: 'referral/get-referral-programs',
  INVITE_PEOPLE:'referral/invite-people',
  
  //Get Price Detail
  GET_PRICE_DETAIL:'general/get-booking-price',
  BOOK_NOW:'booking/book-now',
  REQUEST_BOOKING:'booking/request-booking',
  APPLY_COUPON:'booking/apply-coupon',
  //Get Wallet Balance
  GET_WALLET_BALANCE:'wallet/balance',
  GET_REWARD_POINTS:'rewardpoints/balance',
  CREATE_WALLET_PAYMENT_INTENT:'wallet/stripe/create-payment-intent',
  ADD_TO_WALLET:'wallet/addmoney',
  BRAINTREE_ADD_TO_WALLET:'wallet/btreeaddmoney',
  GET_WALLET_TRANSACTIONS:'wallet/transactions',
  GET_REWARD_TRANSACTIONS:'rewardpoints/transactions',
  ADD_REDEEN_POINTS:'rewardpoints/redeem',

  //Stripe
  GET_PAYMENT_INTENT:'booking/stripe-payment-intent',
  SUBMIT_PAYMENT:'booking/confirm-stripe-book',
  APPLY_WALLET:'booking/apply-wallet-ballance',
  SUBMIT_WALLET_PAYMENT:'booking/confirm-wallet-booking',
  SUBMIT_PAYPAL:'booking/confirm-btree-booking',
  GET_PAYPAL_TOKEN:'booking/btree-token',
  APPLY_REWARD_POINTS: 'booking/apply-reward-points',

  //Transactions
  Transactions:'profile/my-transactions',
  Notifications:'notifications/get',
  ReadNotifications:'notifications/set-read',
  CreateNotifications:'notifications/set',

  //Chat
  CreateChat:'chat/set',
  GetChats:'chat/get',
  ReadChat:'chat/set-read',
  DeleteChat:'chat/delete-chat',
  UploadAttachmentChat:'chat/upload-attachment',
  DeleteAttachmentChat:'chat/delete-attachment',


};

