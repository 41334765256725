import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-makes-us-special',
  templateUrl: './what-makes-us-special.component.html',
  styleUrls: ['./what-makes-us-special.component.css']
})
export class WhatMakesUsSpecialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
