import { Component, OnInit } from '@angular/core';
import { ROLE } from 'src/app/app.constants';

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
  styleUrls: ['./dashboard-sidebar.component.css']
})
export class DashboardSidebarComponent implements OnInit {
  userCurrentlySelectedRole: string | null;

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.userCurrentlySelectedRole = localStorage.getItem(ROLE);
  }
}
